<template>
  <modal v-model:show="modal" size="lg" body-classes="px-3">
    <template #header>
      <h6 class="modal-title">수행연습나누기 {{ modalModeText }}</h6>
    </template>
    <card type="secondary" body-classes="px-lg-5">
      <form role="form" class="ms-2 me-2">
        <div class="row">
          <label class="form-control-label">나누기주제</label>
        </div>
        <QuillEditor
          id="editor"
          ref="quillEditor"
          v-model:content="newData.post"
          content-type="html"
          theme="snow"
          toolbar="full"
          :modules="modules"
        />
        <preset-radio
          v-model="newData.grade"
          class="mb-3 mt-3"
          :choices="[
            ['불교대학', '불교대학'],
            ['경전대학', '경전대학'],
          ]"
        />
        <base-input v-model="newData.start" type="date">
          <template #addonLeft> 시작일</template>
        </base-input>
        <base-input v-model="newData.end" type="date">
          <template #addonLeft> 종료일</template>
        </base-input>
        <div class="row">
          <label class="form-control-label">공개여부</label>
        </div>
        <div class="row">
          <preset-radio
            v-model="newData.state"
            :choices="[
              ['1', '열림'],
              ['0', '닫힘'],
            ]"
          />
        </div>
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import { ROOM_NUMBER, TEAM_NUMBER, LOCAL_NAMES_B } from "@/consts/class";
import moment from "moment";
import PresetRadio from "@/components/Inputs/PresetRadio";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

export default {
  components: {
    Modal,
    PresetRadio,
    QuillEditor,
  },
  mixins: [EditModalMixin],
  emits: ["addDone", "editDone"],
  data() {
    return {
      someText: "",
      ROOM_NUMBER,
      TEAM_NUMBER,
      LOCAL_NAMES_B,
      query: {
        // 검색 쿼리
        local: "",
        team: "",
        room: "",
      },
      newData: {
        id: 0,
        local: "",
        url: "",
        team: "",
        room: "",
        post: "",
      },
      modules: {
        name: "imageUploader",
        module: ImageUploader,
        options: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append("filelist", file);
              axios
                .post(`${process.env.VUE_APP_API_BASE_URL}/imagesave`, formData)
                .then((res) => {
                  console.log(res);
                  resolve(res.data.url);
                })
                .catch((err) => {
                  reject("Upload failed");
                  console.error("Error:", err);
                });
            });
          },
        },
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.quillEditor;
    },
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    getSetText() {
      this.someText = "<div><p>this is some text</p> </div>";
      this.editor.setHTML(this.someText);
    },
    // 등록하기
    onAdd() {
      const data = JSON.parse(JSON.stringify(this.newData));

      api.post("manage/class2/practiceRetro", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`manage/class2/practiceRetro/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      this.newData.grade = "불교대학";
      this.newData.post = "";
      this.newData.start = "";
      this.newData.end = "";
      this.newData.editorContent = "";
      this.newData.state = 1;
      this.getSetText();
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(`manage/class2/practiceRetro/${row.id}`, row, (data) => {
        this.newData = data;
        return {
          id: data.id,
          post: data.post,
          start: moment(row.start).format("YYYY-MM-DD"),
          end: moment(row.end).format("YYYY-MM-DD"),
          state: data.state,
          editorContent: data.editorContent,
        };
      });
    },
  },
};
</script>
<style>
.ck.ck-balloon-panel {
  z-index: 1050 !important;
}
.ck-editor__editable {
  min-height: 300px;
}
</style>
