<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <!-- <sidenav-collapse2
          collapse-ref="dashboardsExamples"
          nav-text="HOME"
          :class="getRoute() === 'home' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4"> -->
        <!-- nav links -->
        <!-- <sidenav-item
                :to="{ name: 'Main' }"
                mini-icon="M"
                text="활동가공지"
              />
            </ul>
          </template>
        </sidenav-collapse2> -->

        <sidenav-collapse2
          collapse-ref="dashboardsExamples"
          nav-text="출석부"
          :class="getRoute() === 'attend' ? 'active' : ''"
        >
          <template #icon>
            <i
              class="ni ni-calendar-grid-58 text-primary text-sm opacity-10"
            ></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                v-if="verboseSearchOptionClassleader2"
                :to="{ name: 'Online' }"
                mini-icon="온라인"
                text="온라인수업"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleader2"
                :to="{ name: 'TalkClass' }"
                mini-icon="즉문즉설"
                text="즉문즉설수업"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleader2"
                :to="{ name: 'Offline' }"
                mini-icon="실천활동"
                text="실천활동수업"
              />
              <sidenav-item
                :to="{ name: 'MeetingAttend' }"
                mini-icon="수기출석"
                text="수업보고서/수기출석부"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleaderAndMentor"
                :to="{ name: 'AttendStats' }"
                mini-icon="개인출석"
                text="개인별출석 현황"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleaderAndMentor"
                :to="{ name: 'AttendStatsTotal' }"
                mini-icon="통계"
                text="전국수업통계"
              />
            </ul>
          </template>
        </sidenav-collapse2>
      </li>
      <!-- <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li> -->

      <li class="nav-item">
        <sidenav-collapse2
          collapse-ref="pagesExamples"
          nav-text="활동가"
          :class="getRoute() === 'manager' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-laptop text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <!-- <li v-if="verboseSearchOptionClassleaderAndMentor" class="nav-item" style="cursor:pointer"> 
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(manualForRoom)"
                >
                  <span class="text-xs sidenav-mini-icon">안내</span>
                  <div title="담당용"><span class="sidenav-normal" >사이트 사용 안내<br>(담당용)</span></div>
                </a>
              </li>
              <li class="nav-item" style="cursor:pointer"> 
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(manualForTeam)"
                >
                  <span class="text-xs sidenav-mini-icon">안내</span>
                  <div title="진행자/돕는이용"><span class="sidenav-normal" >사이트 사용 안내<br>(진행자/돕는이용)</span></div>
                </a>
              </li>
              <li class="nav-item" style="cursor:pointer"> 
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(buldaeJin)"
                >
                  <span class="text-xs sidenav-mini-icon">진불</span>
                  <span class="sidenav-normal">불교대-진행자료홈 </span>
                </a>
              </li>
              <li v-if="verboseSearchOptionClassleader" class="nav-item" style="cursor:pointer">
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(buldaeDam)"
                >
                  <span class="text-xs sidenav-mini-icon">담불</span>
                  <span class="sidenav-normal">불교대-담당홈</span>
                </a>
              </li>
              <li class="nav-item" style="cursor:pointer">
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(dhammaJin)"
                >
                  <span class="text-xs sidenav-mini-icon">진경</span>
                  <span class="sidenav-normal">경전대-진행자료홈 </span>
                </a>
              </li>

              <li v-if="verboseSearchOptionClassleader" class="nav-item" style="cursor:pointer">
                <a
                  class="nav-link"
                  target="_blank"
                  @click="goMeet1(dhammaDam)"
                >
                  <span class="text-xs sidenav-mini-icon">담경</span>
                  <span class="sidenav-normal">경전대-담당홈</span>
                </a>
              </li> -->
              <sidenav-item
                :to="{ name: 'Team' }"
                mini-icon="조별주소"
                text="조별주소"
              />
              <sidenav-item
                :to="{ name: 'Student' }"
                mini-icon="학생"
                text="학생명단"
              />

              <!-- <sidenav-item
                :to="{ name: 'Proposal' }"
                mini-icon="제"
                text="제안게시판"
              /> -->

              <sidenav-item
                v-if="verboseSearchOptionClassleader"
                :to="{ name: 'Activist' }"
                mini-icon="활동가"
                text="활동가명단"
              />

              <!-- <sidenav-item
                :to="{ name: 'Frequency' }"
                mini-icon="FAQ"
                text="자주묻는질문"
              /> -->

              <sidenav-item
                v-if="verboseSearchOptionMentor"
                :to="{ name: 'Question' }"
                mini-icon="질문.개인"
                text="법사님께질문(개인)"
              />
              <sidenav-item
                v-if="verboseSearchOptionMentor"
                :to="{ name: 'Question2' }"
                mini-icon="질문.전체"
                text="법사님께질문(전체)"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleaderAndMentor"
                :to="{ name: 'Room' }"
                mini-icon="담당법사"
                text="반담당법사"
              />
              <sidenav-item
                v-if="verboseSearchOptionClassleaderAndMentor"
                :to="{ name: 'PracticeRetro' }"
                mini-icon="나수행연습"
                text="수행연습나누기"
              />

              <!-- 관리자는 수정가능한 화면, 그 외 보기만 가능한 화면 보여줌 -->
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'NoticeManager' }"
                mini-icon="활"
                text="활동가공지"
              />
              <sidenav-item
                v-else
                :to="{ name: '/' }"
                mini-icon="활"
                text="활동가공지"
              />

              <sidenav-item
                :to="{ name: 'MyInformation' }"
                mini-icon="내정보"
                text="내정보"
              />
            </ul>
          </template>
        </sidenav-collapse2>
        <sidenav-collapse2
          v-if="verboseSearchOptionManager2"
          collapse-ref="dashboardsExamples"
          nav-text="학 사"
          :class="getRoute() === 'student' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-hat-3 text-danger text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Lesson' }"
                mini-icon="수업"
                text="수업"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'LessonIrregular' }"
                mini-icon="비정규수업"
                text="비정규수업"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager2"
                :to="{ name: 'Lecture' }"
                mini-icon="주소"
                text="법문주소"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Library' }"
                mini-icon="자료실"
                text="수업자료실"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'NoticeStudent' }"
                mini-icon="공지"
                text="학생공지"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Thousand' }"
                mini-icon="천일결사"
                text="천일결사자료실"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Talk' }"
                mini-icon="즉문즉설"
                text="즉문즉설"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Weekend' }"
                mini-icon="실천활동"
                text="실천활동"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'Zoom' }"
                mini-icon="줌 주소"
                text="줌 주소"
              />
              <sidenav-item
                v-if="verboseSearchOptionManager"
                :to="{ name: 'MenuLink' }"
                mini-icon="홈 주소"
                text="홈 주소"
              />
            </ul>
          </template>
        </sidenav-collapse2>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem";
// import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapse2 from "./SidenavCollapse2";
// import SidenavCard from "./SidenavCard.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import { Auth } from "@/api";
// import * as api from "@/api";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    // SidenavCollapse,
    SidenavCollapse2,
    // SidenavCard,
    //SidenavCollapseItem
  },
  data() {
    return {
      buldae: false,
      // buldaeJin: "",
      // buldaeDam: "",
      // dhammaJin: "",
      // dhammaDam: "",
      // manualForRoom: "https://docs.google.com/presentation/d/1zlykTZxpU-sBkiwTIZs3it-RDP4AlVSNwdY154nZhXk/edit#slide=id.p1",
      // manualForTeam: "https://docs.google.com/presentation/d/1GtRGDhypRXOFgVhhjzuCztBKOT-OvipIUSDD6vq2sFs/edit#slide=id.p1",
    };
  },
  created() {
    const token = Auth.tokenContent;

    if (token.grade) {
      this.buldae = token.grade.includes("불교대학");
      this.verboseSearchOptionMaster = token.role.includes("master");
      this.verboseSearchOptionManager =
        token.role.includes("manager") || token.role.includes("master");
      this.verboseSearchOptionManager2 =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("observer");
      this.verboseSearchOptionClassleader =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("observer") ||
        token.role.includes("class leader");
      this.verboseSearchOptionClassleader2 =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("team leader") ||
        token.role.includes("team assist") ||
        token.role.includes("class leader");
      this.verboseSearchOptionMentor =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      this.verboseSearchOptionClassleaderAndMentor =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("branch") ||
        token.role.includes("observer") ||
        token.role.includes("class leader") ||
        token.role.includes("mentor");
      this.verboseSearchOptionWithout = !token.role.includes("mentor");
      this.semesterCode = sessionStorage.getItem("semesterCode");
      this.buldae = token.grade.includes("불교대학");
      // this.getDataFromApi();
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    //   goMeet1(url) {
    //     window.open(url);
    //   },
    //   getDataFromApi() {
    //     const token = Auth.tokenContent;

    //     const url = `manage/class2/menuLink?year=${token.year}&semester=${token.semester}`;
    //     api.get(url).then((response) => {
    //     if (!api.isSuccess(response)) {
    //       return;
    //     }
    //     const data = response.data;
    //     const d = data.data;
    //     if (data.rows > 0) {
    //       for (const row of d) {
    //         if (row.grade == "불교대학" && row.belong == "진행자료홈") {
    //           this.buldaeJin = row.url;
    //         } else if (row.grade == "불교대학" && row.belong == "담당홈") {
    //           this.buldaeDam = row.url;
    //         } else if (row.grade == "경전대학" && row.belong == "진행자료홈") {
    //           this.dhammaJin = row.url;
    //         } else if (row.grade == "경전대학" && row.belong == "담당홈") {
    //           this.dhammaDam = row.url;
    //         }
    //       }
    //     }
    //   });
    //   }
  },
};
</script>
<style></style>
