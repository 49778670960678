<template>
  <base-pagination
    v-model="page"
    :default="1"
    :rows-per-page="rowsPerPage"
    :page-count="pageCount"
    align="center"
    class="mt-3"
    @update:model-value="onPageChange"
  />
</template>
<script>
export default {
  name: "CommonPagination",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    rowsPerPage: {
      type: Number,
      default: 20,
    },
  },
  emits: ["page-change"],
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.rowsPerPage);
    },
  },
  methods: {
    onPageChange(item) {
      this.$emit("page-change", item);
    },
  },
};
</script>
<style>
.page-link {
  background-color: rgb(212, 212, 222);
}
</style>
