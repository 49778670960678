<!-- 수업 자료실 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">주제질문</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center"></div>
            </div>

            <!-- t: top, b: bottom, s: left, e: right x: left & right y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1" style="position: absolute; bottom: 0px">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 120px"
              >
                total: {{ total }}
              </div>
              <div
                v-if="inputPossibleMaster"
                class="col text-end"
                style="bottom: 0px"
              >
                <base-button type="primary" class="btn-sm" @click="handleAdd"
                  >+추가</base-button
                >
              </div>
            </div>

            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h2>주제질문</h2>
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row-sm-3 mt-3">
                      <preset-radio
                        v-model="newData.grade"
                        disabled
                        :choices="[['불교대학', '불교대학']]"
                      />
                    </div>
                    <div class="row">&nbsp;</div>

                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">나누기주제</label>
                    </div>
                    <div :key="htmlEditorKey">
                      <html-editor v-model="newData.post" />
                    </div>
                    <div class="col ps-1 mt-4">
                      <base-input
                        v-model="newData.start"
                        type="date"
                        name="start"
                      >
                        <template #addonLeft> 시작일</template>
                      </base-input>
                    </div>
                    <div class="col ps-1 mt-4">
                      <base-input v-model="newData.end" type="date" name="end">
                        <template #addonLeft> 종료일</template>
                      </base-input>
                    </div>
                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">공개여부</label>
                    </div>
                    <div class="row">
                      <preset-radio
                        v-model="newData.state"
                        :choices="[
                          ['1', '공개'],
                          ['0', '비공개'],
                        ]"
                      />
                    </div>
                    <div class="text-center">
                      <base-button
                        v-if="add"
                        type="primary"
                        class="my-4"
                        @click="onAdd(newData)"
                        >등록 하기</base-button
                      >
                      <base-button
                        v-else
                        type="primary"
                        class="my-4"
                        @click="onEdit(newData)"
                        >수정 하기</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>
            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td
                      width="20%"
                      min-width="100px"
                      style="word-break: break-all"
                    >
                      <p v-dompurify-html="row.post"></p>
                    </td>

                    <td>{{ row.start }}</td>
                    <td>{{ row.end }}</td>
                    <td>{{ row.userName }}</td>
                    <td>
                      <span :class="row.statusClass">● </span>{{ row.status }}
                    </td>
                    <td width="10%">
                      <div class="d-flex pe-4">
                        <base-button
                          v-if="verboseSearchOptionManager"
                          class="edit me-1"
                          type="warning"
                          size="sm"
                          icon
                          @click.stop="handleEdit(row)"
                        >
                          <i class="text-white fa fa-pen" />
                        </base-button>
                        <base-button
                          v-if="verboseSearchOptionManager"
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click.stop="handleDelete(row)"
                        >
                          <i class="text-white ni ni-fat-remove" />
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import moment from "moment";
import { useRouter } from "vue-router";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
    HtmlEditor,
  },

  setup() {
    const tableData = ref([]);
    const newData = ref({});
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const query = ref({
      grade: "불교대학",
      post: "",
      bound: 20,
    });
    let previewTitle = ref("");
    let previewBody = ref("");
    let showPreviewModal = ref(false);
    let htmlEditorKey = ref(0);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    let modalActive = ref(false);
    const add = ref(false);
    const header = ref([
      "No",
      "학년",
      "주제질문",
      "시작일",
      "종료일",
      "작성자",
      "상태",
      "Action",
    ]);

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    const getDataFromApi = (page = 1) => {
      page2.value = page;

      const url = `manage/class2/subject?bound=${query.value.bound}
   &post=${query.value.post}&page=${page}&grade=${query.value.grade}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            updateRowStatus(row, row.state);
            row.start = moment(row.start).format("YYYY-MM-DD");
            row.end = moment(row.end).format("YYYY-MM-DD");
            row.post = row.post.split("\n").join("<br>");
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const updateRowStatus = (row, state) => {
      if (state === 1) {
        row.status = "공개";
        row.statusClass = "text-success";
      } else {
        row.status = "비공개";
        row.statusClass = "text-danger";
      }
    };
    const router = useRouter();
    const goShare = (row) => {
      router.push({
        name: "SharePractice",
        query: {
          id: row.id,
        },
      });
    };

    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
      showPreviewModal.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.title = "";
      newData.value.post = "";
      newData.value.state = "";
      newData.value.grade = "";
      htmlEditorKey.value++;
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/subject/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/subject", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/subject/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/subject/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      header,
      page2,
      newData,
      query,
      getDataFromApi,
      previewBody,
      previewTitle,
      htmlEditorKey,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      modalActive,
      closedModal,
      goShare,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      showPreviewModal,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
.test {
  max-width: 500px;
  word-break: break-all;
}
table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
  word-break: break-all;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
