import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { API_BASE_URL, AUTH_REDIRECT_URL } from "@/consts/urls";
import { GOOGLE_CLIENT_ID } from "@/consts/credentials";
import {
  STATUS_CODES,
  SUCCESS,
  SUCCESS_BUT_EMPTY,
  ERROR_TOKEN_EXPIRED,
  ERROR_NO_PERMISSION,
} from "@/consts/codes";

const ResponseStatus = {
  errorMap: Object.fromEntries(STATUS_CODES.map((item) => [item.Code, item])),
  getCode(response) {
    return response?.data?.code;
  },
  isSuccess(response) {
    const code = this.getCode(response);
    return code === SUCCESS || code === SUCCESS_BUT_EMPTY;
  },
  isAuthErrorCode(code) {
    return this.errorMap[code]?.Category === "Auth";
  },
  isTokenExpired(response) {
    const code = this.getCode(response);
    return code === ERROR_TOKEN_EXPIRED;
  },
  describeError(response) {
    const code = this.getCode(response);
    if (code === ERROR_NO_PERMISSION) {
      return "실행 권한이 없습니다. 권한 확인 바랍니다.";
    } else if (this.isAuthErrorCode(code)) {
      return (
        "인증 실패했습니다. 로그인 됐는지 확인하시고 다시 시도해주세요.(" +
        code +
        ")"
      );
    } else {
      return (
        response?.data?.msg ||
        this.errorMap[code]?.Description ||
        "요청을 처리할 수 없습니다. 다시 시도해주세요."
      );
    }
  },
};
const isSuccess = ResponseStatus.isSuccess.bind(ResponseStatus);

axios.interceptors.request.use(
  (config) => {
    // accessToken 설정된 경우 Authorization 헤더 추가
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    const originalRequest = response.config;
    const refreshToken = sessionStorage.getItem("refreshToken");
    if (
      ResponseStatus.isTokenExpired(response) &&
      refreshToken &&
      !originalRequest._retry
    ) {
      // 토근 만료된 경우, 새로운 토큰으로 갱신하고, 원래 요청 재시도
      console.debug("expired token detected -> refresh token");
      originalRequest._retry = true;
      const url = new URL("/auth", API_BASE_URL).href;
      return axios.put(url, { token: refreshToken }).then((res) => {
        console.debug("refresh was successful.");
        const newToken = res?.data?.data?.accessToken;
        if (ResponseStatus.isSuccess(res) && newToken) {
          console.debug(
            "set new token -> retry original request with new token"
          );
          sessionStorage.setItem("accessToken", newToken);
          return axios(originalRequest);
        } else {
          return res;
        }
      });
    }
    return response;
  },
  (error) => Promise.reject(error)
);

function fetch(path, options = {}, method = "get") {
  const url = new URL(path, API_BASE_URL).href;
  options = Object.assign({}, options);
  options.method = method;

  return axios(url, options).then((response) => {
    if (!ResponseStatus.isSuccess(response)) {
      alert(ResponseStatus.describeError(response));
    }
    return response;
  });
}

function download(path, options = {}) {
  const url = new URL(path, API_BASE_URL).href;
  options = Object.assign({}, options);
  options.method = "get";
  options.responseType = "arraybuffer";

  return axios(url, options).then((response) => {
    let blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let today = moment().local().format("YYYY-MM-DD HH:mm");
    let fileName = "출석부(" + today + ").xlsx";
    if (response.config.url.includes("meetingExcelDownload")) {
      fileName = "회의록(" + today + ").xlsx";
    }

    if (window.navigator.msSaveOrOpenBlob) {
      // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // not IE
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_self";
      if (fileName) link.download = fileName;
      link.click();
    }
  });
}

const get = (path, options) => fetch(path, options, "get");
const post = (path, options) => fetch(path, options, "post");
const put = (path, options) => fetch(path, options, "put");
const delete_ = (path, options) => fetch(path, options, "delete");

function getStub(stubResponse, delay = 300) {
  return new Promise((resolve) =>
    setTimeout(() => resolve(stubResponse), delay)
  );
}
const Auth = {
  login() {
    if (process.env.NODE_ENV === "development") {
      // gapi is from https://apis.google.com/js/platform.js which is included in index.html
      /* global gapi */
      gapi.load("auth2", function () {
        const gauth = gapi.auth2.init({ client_id: GOOGLE_CLIENT_ID });
        gauth.signIn({
          ux_mode: "redirect",
          redirect_uri: AUTH_REDIRECT_URL,
          scope: "email profile",
        });
      });
    } else {
      gapi.load("auth2", function () {
        const gauth = gapi.auth2.init({ client_id: GOOGLE_CLIENT_ID });
        gauth.signIn({
          ux_mode: "redirect",
          redirect_uri: AUTH_REDIRECT_URL,
          scope: "email profile",
        });
      });
    }
    return;
  },

  join() {
    document.location.replace(
      "https://accounts.google.com/signup/v2/webcreateaccount?continue=https%3A%2F%2Fwww.google.com&hl=ko&gmb=exp&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp"
    );
  },

  loginDone() {
    const idToken = document.location.hash.match(/id_token=([^&]+)/)[1];

    post("/auth", {
      data: JSON.stringify({ pit: idToken }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        const payload = response.data.data;

        if (isSuccess(response)) {
          sessionStorage.setItem("accessToken", payload.accessToken);
          sessionStorage.setItem("refreshToken", payload.refreshToken);
          sessionStorage.setItem("email", payload.email);
          sessionStorage.setItem("name", payload.name);
          sessionStorage.setItem("nickname", payload.nickname);
          sessionStorage.setItem("role", payload.role);
          sessionStorage.setItem("pit", idToken); //confirm pit(platform id token). delete after develop

          if (payload?.picture)
            sessionStorage.setItem("picture", payload.picture);

          if (sessionStorage.getItem("fullPath"))
            document.location.replace(
              "/#" + sessionStorage.getItem("fullPath")
            );
          else document.location.replace("/");

          sessionStorage.removeItem("fullPath");
        } else {
          alert("login failure");
          document.location.replace("/#/login");
        }
      })
      .catch((err) => {
        alert(err);
        document.location.replace("/#/login");
      });
  },

  logout(router) {
    localStorage.clear();
    sessionStorage.clear();
    router.push({ name: "Login" });
  },

  get isLoggedIn() {
    return !!sessionStorage.getItem("accessToken");
  },

  get tokenContent() {
    const accessToken = sessionStorage.getItem("accessToken");
    if (!accessToken) {
      return { role: [] };
    }
    return jwtDecode(accessToken);
  },
};

export { Auth, isSuccess, getStub, get, post, put, delete_, download };
