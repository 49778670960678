<template>
  <quill-editor
    theme="snow"
    content-type="html"
    :content="modelValue"
    :toolbar="toolbar"
    @update:content="$emit('update:modelValue', $event)"
  />
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  name: "HtmlEditor",
  components: {
    QuillEditor,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    toolbar: {
      type: String,
      default: "full",
    },
  },
  emits: ["update:modelValue"],
};
</script>
