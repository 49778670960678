<!-- 수업 자료실 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">법사님께 질문(개인)</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.grade"
                    placeholder="학년"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      ['불교대학', '불교대학'],
                      ['경전대학', '경전대학'],
                    ]"
                    @change="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    size="large"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.state"
                    placeholder="답변완료/질문"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      ['1', '답변완료'],
                      ['0', '질문'],
                    ]"
                    @change="getDataFromApi()"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.name"
                    type="text"
                    class="form-control col"
                    placeholder="학생이름"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.mentor"
                    type="text"
                    class="form-control col"
                    placeholder="담당법사"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.postQuestion"
                    type="text"
                    class="form-control col"
                    placeholder="질문"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.postAnswer"
                    type="text"
                    class="form-control col"
                    placeholder="답변"
                    @keyup.enter="getDataFromApi(1)"
                  />
                </div>
                <div class="col">
                  <base-button
                    class="btn-sm-2 mt-3"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & right y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <!-- Preview Modal -->
            <Modal :modal-active="showPreviewModal" @close="closedModal">
              <template #header>
                <h6 class="modal-title">
                  <div></div>
                </h6>
              </template>
              <div class="modal-content">
                <ul class="list-group list-group-flush list">
                  <!-- style="border:1px solid black;border-radius:10px/10px" -->

                  <li class="list-group px-0">
                    <td class="list-group-item px-2">
                      <H5>질문학생: {{ name }}</H5>
                      <div
                        class="mr-3"
                        style="float: right; display: inline-block"
                      ></div>
                    </td>
                    <td class="list-group-item px-0">
                      <textarea
                        id="exampleFormControlTextarea3"
                        v-model="previewQuestion"
                        class="form-control mx-0"
                        style="width: 600px"
                        rows="10"
                        disabled
                      />
                    </td>
                    <td v-if="previewAnswer" class="list-group-item px-2">
                      <H5>답변법사: {{ mentor }}</H5>
                    </td>
                    <td v-if="previewAnswer" class="list-group-item px-2">
                      <div v-dompurify-html="previewAnswer" class="mx-3"></div>
                    </td>

                    <br />
                  </li>
                </ul>

                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </div>
            </Modal>
            <!-- Preview Modal -->
            <Modal :modal-active="modalActive" @close="closedModal">
              <template #header>
                <h6 class="modal-title">담당법사 답변</h6>
              </template>
              <form role="form">
                <h4>학생이름 : {{ newData.name }}</h4>
                <base-input class="mr-3 ml-10">
                  <textarea
                    id="exampleFormControlTextarea3"
                    v-model="newData.postQuestion"
                    class="form-control mx-3"
                    style="width: 600px"
                    rows="10"
                    disabled
                  />
                </base-input>
                <h4>{{ newData.mentor }} : 담당법사답변</h4>
                <div :key="htmlEditorKey">
                  <html-editor v-model="newData.postAnswer" />
                </div>

                <div class="text-center">
                  <base-button
                    type="primary"
                    class="my-4"
                    @click="onSave2(newData)"
                  >
                    {{ question }}</base-button
                  >
                  <base-button
                    type="secondary"
                    class="my-4"
                    @click="closedModal()"
                    >닫 기</base-button
                  >
                </div>
              </form>
            </Modal>
            <div class="card-body">
              <table align="center" width="80%">
                <ul v-if="isQuestion" class="list-group list-group-flush list">
                  <tr class="list-group-item">
                    <td width="50%"></td>
                    <td width="30%">{{ message }}</td>
                    <td width="20%"></td>
                  </tr>
                </ul>
                <ul v-else class="list-group list-group-flush list">
                  <!-- style="border:1px solid black;border-radius:10px/10px" -->

                  <li
                    v-for="row in tableData"
                    :key="row.id"
                    class="list-group px-0"
                  >
                    <tr class="list-group-item">
                      <td width="10%" align="right">학생이름:</td>
                      <td width="10%" align="left">
                        <h6 @click="queryName(row, 1)">{{ row.name }}</h6>
                      </td>
                      <td width="10%" align="right">소속:</td>
                      <td width="20%" align="left">
                        {{ row.local }}/{{ row.room }}반/{{ row.team }}조
                      </td>
                      <td width="12.5%" align="right">질문상태:</td>
                      <td width="12.5%" align="left">
                        {{ row.status }}
                      </td>
                      <td width="12.5%" align="right">질문날짜:</td>
                      <td width="12.5%" align="left">
                        {{ row.createdTime }}
                      </td>
                    </tr>
                    <td class="list-group-item px-2">
                      <div
                        v-dompurify-html="row.postQuestion"
                        class="mx-3"
                      ></div>
                    </td>
                    <td class="list-group-item px-2">
                      <h6 @click="queryMentor(row)">
                        <div
                          class="mx-3"
                          style="float: left"
                          v-text="row.mentor"
                        ></div>
                      </h6>
                      <div
                        class="mr-3"
                        style="float: right; display: inline-block"
                        v-text="row.lastUpdated"
                      ></div>
                    </td>
                    <td v-if="row.postAnswer" class="list-group-item px-2">
                      <div v-dompurify-html="row.postAnswer" class="mx-3"></div>
                      <base-button type="primary" @click.stop="handleEdit(row)">
                        <i class="ni ni-send"></i> 답변 수정
                      </base-button>
                    </td>
                    <td v-else class="list-group-item px-2">
                      <div :key="htmlEditorKey">
                        <html-editor v-model="row.ans" />
                      </div>
                      <br />
                      <div v-if="row.visible" class="col-sm-4 mx-3">
                        <base-button type="primary" @click="onSave(row)">
                          <i class="ni ni-send"></i> 답변 보내기
                        </base-button>
                      </div>
                    </td>

                    <br />
                  </li>
                </ul>
              </table>
            </div>
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import Modal from "./Board/Modal";
import moment from "moment";
import { ROOM_NUMBER, TEAM_NUMBER, LOCAL } from "@/consts/class";
import sanitizeHtml from "sanitize-html";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    Modal,
    HtmlEditor,
  },

  setup() {
    const tableData = ref([]);
    const newData = ref({});
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const query = ref({
      room: "",
      team: "",
      local: "",
      grade: "",
      name: "",
      mentor: "",
      postQuestion: "",
      postAnswer: "",
      state: "",
    });
    let previewTitle = ref("");
    let previewBody = ref("");
    let showPreviewModal = ref(false);
    let htmlEditorKey = ref(0);
    let verboseSearchOptionManager = ref(false);
    let inputPossibleBranch = ref(false);
    let inputPossibleMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let inputPossibleClassLeader = ref(false);
    let modalActive = ref(false);
    let name = ref("");
    let mentor = ref("");
    let previewQuestion = ref("");
    let previewAnswer = ref("");
    let year = ref("");
    let semester = ref("");
    let question = ref("");
    let ans = ref("");

    const add = ref(false);
    const header = ref([
      "No",
      "담당법사",
      "학생",
      "질문",
      "상태",
      "소속",
      "질문날짜",
      "답변날짜",
      "Action",
    ]);

    const getDataFromApi = (page = 1) => {
      page2.value = page;

      for (const field of ["local", "team", "room", "mentor"]) {
        if (query.value[field] === "전체") {
          query.value[field] = "";
        }
      }
      const url = `manage/class2/questionPerson?postQuestion=${query.value.postQuestion}&postAnswer=${query.value.postAnswer}&name=${query.value.name}&mentor=${query.value.mentor}&page=${page}&state=${query.value.state}&local=${query.value.local}&room=${query.value.room}&team=${query.value.team}&grade=${query.value.grade}&year=${year.value}&semester=${semester.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            const temp = row.postQuestion.length;
            if (temp > 30) {
              row.postQuestionShort = row.postQuestion.substr(0, 30) + "...";
            } else {
              row.postQuestionShort = row.postQuestion;
            }
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
            row.lastUpdated = moment(row.lastUpdated).format("YYYY-MM-DD");

            row.org =
              row.grade.substr(0, 2) +
              "/" +
              row.local +
              "/" +
              row.room +
              "/" +
              row.team;
            if (row.state === 1) {
              row.status = "답변완료";
              row.question = "답변수정";
              row.lastUpdated = moment(row.lastUpdated).format("YYYY-MM-DD");
            } else {
              row.status = "질문";
              row.question = "답변쓰기";
              row.lastUpdated = "";
              row.ans = "";
            }
            if (row.mentor === sessionStorage.getItem("name")) {
              row.visible = true;
            } else {
              row.visible = false;
            }
            row.postQuestion = row.postQuestion.split("\n").join("<br>");
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi(1);
    verboseSearchOptionManager = true;
    inputPossibleBranch = true;
    verboseSearchOptionSemester = true;
    inputPossibleClassLeader = true;
    inputPossibleMaster = true;

    const queryName = (row) => {
      const url = `manage/class2/question?bound=${query.value.bound}
   &name=${row.name}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            const temp = row.postQuestion.length;

            if (temp > 30) {
              row.postQuestionShort = row.postQuestion.substr(0, 30) + "...";
            } else {
              row.postQuestionShort = row.postQuestion;
            }
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
            row.org = row.local + "/" + row.room + "/" + row.team;
            if (row.state === 1) {
              row.status = "답변완료";
              row.question = "답변수정";
            } else {
              row.status = "질문";
              row.question = "답변쓰기";
            }
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const queryMentor = (row) => {
      const url = `manage/class2/question?bound=${query.value.bound}
   &mentor=${row.mentor}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            const temp = row.postQuestion.length;

            if (temp > 30) {
              row.postQuestionShort = row.postQuestion.substr(0, 30) + "...";
            } else {
              row.postQuestionShort = row.postQuestion;
            }
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
            row.org = row.local + "/" + row.room + "/" + row.team;
            if (row.state === 1) {
              row.status = "답변완료";
            } else {
              row.status = "질문";
              row.ans = "";
            }
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const showPreview = (row) => {
      name.value = row.name;
      mentor.value = row.mentor;
      previewQuestion.value = sanitizeHtml(row.postQuestion);
      if (row.postAnswer) {
        previewAnswer.value = sanitizeHtml(row.postAnswer);
      } else {
        const url = `manage/class2/question?bound=${query.value.bound}
   &questionId=${row.questionId}&type=A&state=1`;
        api.get(url).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data = response.data;
          const d = data.data;
          if (data.rows > 0) {
            previewAnswer.value = sanitizeHtml(d[0].post);
          } else {
            previewAnswer.value = "";
          }
        });
      }
      showPreviewModal.value = true;
    };
    const closedModal = () => {
      modalActive.value = false;
      showPreviewModal.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.title = "";
      newData.value.post = "";
      newData.value.state = "";
      newData.value.grade = "";
      htmlEditorKey.value++;
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/question/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        htmlEditorKey.value++;
        modalActive.value = true;
        newData.value = response.data.data;
        if (newData.value.state === 1) {
          question.value = "답변수정";
        } else {
          question.value = "답변쓰기";
        }

        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/question", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onSave = (row) => {
      const data = {};
      data.postAnswer = row.ans;
      api.put(`manage/class2/question/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    const onSave2 = (row) => {
      const data = {};
      data.postAnswer = row.postAnswer;
      api.put(`manage/class2/question/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/question/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      mentor,
      ans,
      question,
      ROOM_NUMBER,
      TEAM_NUMBER,
      LOCAL,
      header,
      newData,
      query,
      getDataFromApi,
      queryName,
      queryMentor,
      previewBody,
      previewTitle,
      htmlEditorKey,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      inputPossibleBranch,
      inputPossibleMaster,
      inputPossibleClassLeader,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onSave,
      onSave2,
      handleAdd,
      handleDelete,
      showPreview,
      showPreviewModal,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}
table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: left;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
