<!-- 반정보 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">반담당법사</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div class="col-sm-2">
                  <base-input
                    v-model="query.name"
                    placeholder="이름"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div v-if="verboseSearchOptionMaster" class="col text-end">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                </div>
                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h1>반정보</h1>
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row-sm-3 mb-3">
                      <preset-radio
                        v-model="newData.grade"
                        :choices="[
                          ['불교대학', '불교대학'],
                          ['경전대학', '경전대학'],
                        ]"
                      />
                    </div>
                    <div class="row ps-1">
                      <div class="col-sm-3">
                        <preset-select
                          v-model="newData.local"
                          placeholder="지역"
                          :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                        />
                      </div>
                      <div class="col-sm-3">
                        <preset-select
                          v-model="newData.room"
                          placeholder="반"
                          :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                        />
                      </div>
                    </div>
                    <div class="col ps-1 mt-4">
                      <base-input v-model="newData.mentor" type="text">
                        <template #addonLeft> 담당법사</template>
                      </base-input>
                    </div>

                    <div class="text-center">
                      <base-button
                        v-if="add"
                        type="primary"
                        class="my-4"
                        @click="onAdd"
                        >등록 하기</base-button
                      >
                      <base-button
                        v-else
                        type="primary"
                        class="my-4"
                        @click="onEdit(newData)"
                        >수정 하기</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.semester }}</td>
                    <td>{{ row.local }}</td>
                    <td>{{ row.room }}</td>
                    <td>{{ row.mentor }}</td>
                    <td width="10%">
                      <div v-if="isAction" class="d-flex pe-4">
                        <argon-button
                          v-if="verboseSearchOptionMaster"
                          class="me-1"
                          color="warning"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleEdit(row)"
                        >
                          수정
                        </argon-button>
                        <argon-button
                          v-if="verboseSearchOptionMaster"
                          color="danger"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleDelete(row)"
                        >
                          삭제
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import { ROOM_NUMBER, LOCAL_NAMES_B, SEMESTER } from "@/consts/class";
import { mapState } from "vuex";

export default {
  // 컴포넌트 초기화
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
    PresetRadio,
    Modal,
  },

  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년",
      "학 기",
      "지 역",
      "반",
      "담당법사",
      "Action",
    ]);
    const query = ref({
      semester: "",
      local: "",
      room: "",
      team: "",
      name: "",
      bound: 20,
    });
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionManager2 = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    const newData = ref({});
    let modalActive = ref(false);
    const add = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    modalActive.value = false;
    let isAction = ref(true);
    const token = api.Auth.tokenContent;
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      if (year.value == token.year && semester.value == token.semester) {
        isAction.value = true;
      } else {
        isAction.value = false;
      }
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }

      for (const field of ["local", "team", "room"]) {
        if (query.value[field] === "전체") {
          query.value[field] = "";
        }
      }
      if (
        query.value.semester &&
        (token.role === "manager" || token.role === "master")
      ) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
      }
      const url = `manage/class2/room?bound=${query.value.bound}
   &page=${page}&local=${query.value.local}&room=${query.value.room}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&name=${query.value.name}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionManager2.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      // verboseSearchOptionBranch2.value =
      //   token.role.includes("manager") ||
      //   token.role.includes("master") ||
      //   token.role.includes("mentor");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.local = "";
      newData.value.room = "";
      newData.value.team = "";
      newData.value.grade = "";
      newData.value.belong = "";
      newData.value.url = "";
      newData.value.mentor = "";
      newData.value.start = "";
      newData.value.talkStartTime1 = "";
      newData.value.talkStartTime2 = "";
      newData.value.talkStartTime3 = "";
      newData.value.talkStartTime4 = "";
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/room/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/room", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/room/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/room/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      header,
      page2,
      ROOM_NUMBER,
      LOCAL_NAMES_B,
      SEMESTER,
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionManager2,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      isAction,
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
