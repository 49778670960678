<!-- 사이트 오류 소통 및 제안 게시판 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">
            사이트 오류 소통 및 제안 게시판
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col-sm-2 mr-1">
                  <base-input
                    v-model="query.title"
                    placeholder="제목"
                    @change="getDataFromApi()"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>

                <div class="col-sm-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div class="col-sm-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click.prevent="reset()"
                    >초기화</base-button
                  >
                </div>
                <div class="col text-right">
                  <!-- New Button -->
                  <base-button
                    type="primary"
                    class="btn-sm"
                    @click="$refs.editModal.handleAdd()"
                    >+추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
            <!-- modal -->
            <edit-modal
              ref="editModal"
              @add-done="onAddDone"
              @edit-done="onEditDone"
            />
            <!-- List -->
            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <!-- <th v-for="item in header" :key="item.index">{{ item }}</th> -->
                  <th width="5%" min-width="50px" class="text-center">번호</th>
                  <th width="30%" min-width="250px" class="text-center">
                    제목
                  </th>
                  <th width="10%" min-width="100px" class="text-center">
                    작성자
                  </th>
                  <th width="10%" min-width="100px" class="text-center">
                    작성일
                  </th>
                  <th width="10%" min-width="150px" class="text-center">
                    조회
                  </th>
                  <th width="10%" min-width="150px" class="text-center">
                    댓글/대댓글
                  </th>
                  <th width="20%" min-width="200px">Action</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td class="text-center">{{ row.no }}</td>
                    <td class="text-center">
                      <span class="clickable" @click="goContent(row)">
                        <div
                          class="font-weight-600"
                          style="color: cornflowerblue"
                        >
                          <p v-dompurify-html="row.title"></p>
                        </div>
                      </span>
                    </td>
                    <td class="text-center">{{ row.writerName }}</td>
                    <td class="text-center">{{ row.createdTime }}</td>
                    <td class="text-center">{{ row.hit }}</td>
                    <td class="text-center">{{ row.commentHit3 }}</td>
                    <td v-if="row.isEdit" width="10%">
                      <div class="d-flex pe-4">
                        <argon-button
                          class="me-1"
                          color="warning"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="$refs.editModal.handleEdit(row)"
                        >
                          수정
                        </argon-button>
                        <argon-button
                          color="danger"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleDelete(row)"
                        >
                          삭제
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ElTable, ElTableColumn } from "element-plus";
import EditModal from "./Board/EditModal.vue";
import moment from "moment";
import * as api from "@/api";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    EditModal,
  },

  // 함수 추가
  setup() {
    const header = ref([
      "번호",
      "제목",
      "작성자",
      "작성일",
      "조회",
      "댓글/대댓글",
      "Action",
    ]);
    const tableData = ref([]); // 레코드셋
    const query = ref({
      title: "",
      receivedTeam: "",
      startDate: "",
    });
    const newData = ref({});
    const inputPossible = ref(false);
    const perPage = ref(20); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const reset = () => {
      query.value.title = "";
      getDataFromApi();
    };
    const getContent = (content) => {
      return content.split("\n").join("<br>");
    };
    // api 호출
    const getDataFromApi = (page = 1) => {
      //쿼리조건 추가
      perPage.value = query.value.bound;
      const url = `manage/class2/proposal2?bound=${perPage.value}&page=${page}&title=${query.value.title}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        if (data.rows > 0) {
          for (const row of d) {
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
            if (row.all === "기간") {
              row.startDate = moment(row.startDate).format("YYYY-MM-DD");
              row.endDate = moment(row.endDate).format("YYYY-MM-DD");
            }
            row.hit = row.contentHit;
            row.isEdit = false;
            if (row.writerMemberNo == token.memberNo) {
              row.isEdit = true;
            } else if (token.role == "manager" || token.role == "master") {
              row.isEdit = true;
            }
            row.commentHit4 = row.commentHit2.reduce(function add(
              sum,
              currValue
            ) {
              return sum + currValue;
            },
            0);
            row.commentHit5 = 0;
            if (row.double) {
              let i = 0;
              for (const item of row.double) {
                i = i + item.length;
              }
              row.commentHit5 = i;
            }
            row.commentHit3 = row.commentHit4 + "/" + row.commentHit5;
          }
        }

        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi();
    const router = useRouter();
    const goContent = (row) => {
      const token = api.Auth.tokenContent;
      let data = {};
      if (!row.read.includes(token.memberNo)) {
        row.read.push(token.memberNo);
        data.read = row.read;
      }
      if (row.contentHit) {
        data.contentHit = row.contentHit + 1;
      } else {
        data.contentHit = 1;
      }
      api.put(`manage/class2/proposal/${row.id}`, { data }).then(() => {});
      router.push({
        name: "Comment",
        query: {
          suid: row.id,
        },
      });
    };

    // 등록 완료
    const onAddDone = () => {
      getDataFromApi(1);
    };
    // 수정 완료
    const onEditDone = (row, data) => {
      row.local = data.local;
      tableData.value = tableData.value.slice();
      getDataFromApi(1);
    };
    // 삭제하기
    const handleDelete = (row) => {
      const data = {};
      data.state = 0;
      if (confirm("삭제하시겠습니까?")) {
        api.put(`manage/class2/proposal/${row.id}`, { data }).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      tableData,
      header,
      query,
      newData,
      inputPossible,
      perPage,
      total,
      reset,
      getContent,
      getDataFromApi,
      handleDelete,
      onAddDone,
      onEditDone,
      goContent,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-content");

    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
