import BasePagination from "../components/BasePagination";
import CommonPagination from "@/components/CommonPagination";
import BaseHeader from "../components/BaseHeader";
import BaseButton from "../components/BaseButton";
import ArgonButton from "../components/ArgonButton";
import ArgonRadio from "../components/ArgonRadio";
import BaseInput from "../components/Inputs/BaseInput";

const GlobalComponents = {
  install(app) {
    app.component("BasePagination", BasePagination);
    app.component(CommonPagination.name, CommonPagination);
    app.component("BaseHeader", BaseHeader);
    app.component("BaseButton", BaseButton);
    app.component("BaseInput", BaseInput);
    app.component("ArgonButton", ArgonButton);
    app.component("ArgonRadio", ArgonRadio);
  },
};

export default GlobalComponents;
