<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">즉문즉설수업 출석부</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    size="large"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.lessonNo"
                    type="number"
                    min="1"
                    class="form-control col"
                    placeholder="수업번호"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <preset-select
                    v-model="query.confirm"
                    placeholder="전체"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      [1, '확정'],
                      [0, '대기'],
                    ]"
                  />
                </div>
                <div class="col-sm-2 mt-3">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>

                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.lessonNo }}</td>
                    <td>{{ row.lessonName }}</td>
                    <td>{{ row.lessonDate }}</td>
                    <td>{{ row.roomCode }}</td>
                    <td>{{ row.team }}</td>
                    <td>{{ row.name }}</td>
                    <!-- <td><attend-text :value="row.isMeet" /></td> -->
                    <td>
                      <preset-select
                        v-model = "row.newAttend"                      
                        :choices="[
                          [0, '결석'],
                          [1, '출석'],
                          [-1, '지각'],
                          [-2, '조퇴'],
                        ]"
                        :disabled="row.confirm1 === true"
                        @change="onIsMeetChange($event, row)"
                      />
                    </td>

                    <td v-if="row.newAttend === 0" class="text-warning">
                      <attend-text :value="row.newAttend" />
                    </td>
                    <td v-else class="text-primary">
                      <attend-text :value="row.newAttend" />
                    </td>
                    <td>
                      <argon-switch
                        :checked="row.confirm2"
                        :disabled="row.confirm2"
                        @click.prevent="onAttendChange(row)"
                      >
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                          >{{ row.checkState }}</label
                        >
                      </argon-switch>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import ArgonSwitch from "@/components/ArgonSwitch";
import moment from "moment";
import AttendText from "./AttendText";
import {
  SEMESTER,
  LOCAL_NAMES_B,
  ROOM_NUMBER,
  TEAM_NUMBER,
} from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    AttendText,
    ArgonSwitch,
  },
  setup() {
    const tableData = ref([]);
    const checkState = ref("");
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년",
      "수업번호",
      "수업명",
      "수업일",
      "반",
      "조",
      "이 름",
      // "출결상태",
      "수업참여",
      "출석결과",
      "진행자확인",
    ]);
    const query = ref({
      semester: "",
      local: "",
      room: "",
      team: "",
      confirm: "",
      bound: 20,
      lessonNo: "",
    });
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    let lessonNo = 0;
    let lessonDate = "";
    let section = "";
    let reason = "";

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;

      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }

      if (query.value.local === "전체") {
        query.value.local = "";
      }
      if (query.value.room === "전체") {
        query.value.room = "";
      }
      if (query.value.team === "전체") {
        query.value.team = "";
      } 

      let today = moment().local().format("YYYY-MM-DD HH:mm");
      let startDay1 = moment("2022-09-18 20:00");
      let startDay2 = moment("2022-09-27 20:00");
      if (!query.value.lessonNo) {
        if (grade.value === "불교대학") {
          if (moment(today) < startDay1) {
            query.value.lessonNo = 1;
          }
        } else if (grade.value === "경전대학") {
          if (moment(today) < startDay2) {
            query.value.lessonNo = 1;
          }
        }
      }
      let url2 = "";
      if (query.value.lessonNo) {
        url2 = `manage/class2/talk?lessonNo=${query.value.lessonNo}&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      } else {
        url2 = `manage/class2/talk?today=${today}&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      }

      api.get(url2).then((response) => {
        try {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d2 = data2.data;

          if (query.value.lessonNo) {
            lessonNo = query.value.lessonNo;
          } else {
            lessonNo = d2[0].lessonNo;
          }

          if (lessonNo > -1) {
            lessonDate = d2[0].lessonDate;
            section = d2[0].title;

            const url = `manage/class2/attend3?page=${page}&bound=${query.value.bound}&local=${query.value.local}&team=${query.value.team}&room=${query.value.room}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&lessonNo=${lessonNo}&confirm=${query.value.confirm}`;
            api.get(url).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data = response.data;
              const d = data.data;

              if (data.rows > 0) {
                for (const row of d) {
                  row.lessonNo = lessonNo;
                  row.lessonDate = lessonDate;
                  row.lessonName = section;
                  row.roomCode = row.local + row.room + "반";
                  row.team = row.team + "조";
                  if (row.lessonDate) {
                    row.lessonDate = moment(row.lessonDate).format(
                      "YYYY-MM-DD"
                    );
                  }

                  eval("row.isMeet = row.talkLesson" + lessonNo + ".isMeet");
                  eval("row.newAttend = row.talkLesson" + lessonNo + ".attend");
                  eval(
                    "row.newConfirm = row.talkLesson" + lessonNo + ".confirm"
                  );

                  if (row.newConfirm == 1) {
                    row.confirm1 = true;
                    row.checkState = "확정";
                    row.confirm2 = false;
                  } else {
                    row.confirm1 = false;
                    row.confirm2 = true;
                    row.checkState = "대기";
                  }
                }
              }
              tableData.value = d;
              total.value = data.total;
            });
          }
        } catch (err) {
          console.log(err)
          return;
        }
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
      verboseSearchOptionManager.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const onIsMeetChange = (event, row) => {
      if (row.newConfirm !== 0) {
        return;
      }
      const data = {};
      row.attend = Number(event);
      data.attend = row.attend;
      data.lessonNo = lessonNo;

      api.put(`manage/class2/attend3/${row.id}`, { data }).then((response) => {
        if (!api.isSuccess(response)) {
          alert("출석 체크에 오류가 발생하였습니다. 다시 확인해주세요.")
        }
      });
    };
    const onAttendChange = (newData) => {
      if (newData.newConfirm === 1) {
        reason = prompt(
          "출석 기록을 대기로 변경합니다. 변경 사유를 입력 하세요."
        );
        if (reason === "" || reason === null) {
          return;
        }
        newData.newConfirm = 0;
        newData.checkState = "대기";
      } else {
        let response = confirm("출석 기록을 확정합니다.");
        if (response) {
          newData.newConfirm = 1;
          newData.checkState = "확정";
        } else {
          return;
        }
      }
      let data = {
        newConfirm: newData.newConfirm,
      };
      if (reason && newData.newConfirm === 0) {
        newData.reason = reason;
        data.reason = newData.reason;
        newData.confirm = newData.newConfirm;
        data.confirm = newData.confirm;
        data.lessonNo = lessonNo;
        api
          .put(`manage/class2/attend3/${newData.id}`, { data })
          .then((response3) => {
            if (api.isSuccess(response3)) {
              getDataFromApi();
            }
          });
      }
      if (newData.newConfirm === 0) {
        getDataFromApi();
      } else {
        data = {};
        newData.confirm = newData.newConfirm;
        data.confirm = newData.confirm;
        data.attend = newData.newAttend;
        data.lessonNo = lessonNo;
        api
          .put(`manage/class2/attend3/${newData.id}`, { data })
          .then((response) => {
            if (api.isSuccess(response)) {
               
              getDataFromApi();
            }
          });
        
      }
    };

    return {
      header,
      page2,
      SEMESTER,
      checkState,
      LOCAL_NAMES_B,
      ROOM_NUMBER,
      TEAM_NUMBER,
      semester,
      year,
      query,
      getDataFromApi,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      onIsMeetChange,
      onAttendChange,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
