<!-- 학생 정보 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">학생</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionManager2" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.local"
                    placeholder="지역"
                    size="large"
                    :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.room"
                    placeholder="반"
                    size="large"
                    :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                  />
                </div>
                <div
                  v-if="verboseSearchOptionClassLeader"
                  class="col-sm-2 mb-3"
                >
                  <preset-select
                    v-model="query.team"
                    placeholder="조"
                    size="large"
                    :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                  />
                </div>
                <div
                  v-if="verboseSearchOptionClassLeader"
                  class="col-sm-2 mb-3"
                >
                  <preset-select
                    v-model="query.belong"
                    placeholder="주저"
                    size="large"
                    :choices="[
                      ['', '주저전체'],
                      ['주간', '주간'],
                      ['저녁', '저녁'],
                    ]"
                    @change="getDataFromApi(1)"
                  />
                </div>
                <div
                  v-if="verboseSearchOptionClassLeader"
                  class="col-sm-2 mb-3"
                >
                  <preset-select
                    v-model="query.state"
                    placeholder="상태"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      [ 1, '정상'],
                      [ -11, '과락'],
                      [ -8, '과락(접속허용)'],
                      [ -10, '탈락'],
                      [ -100, '환불'],
                    ]"
                    @change="getDataFromApi(1)"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-input
                    v-model="query.name"
                    placeholder="이름"
                    @change.prevent="getDataFromApi()"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-input
                    v-model="query.email"
                    placeholder="email"
                    @change.prevent="getDataFromApi()"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-input
                    v-model="query.memberNo"
                    placeholder="회원번호"
                    @change.prevent="getDataFromApi()"
                    @keyup.enter.prevent="getDataFromApi()"
                  />
                </div>
                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-button
                    class="btn-sm"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div v-if="verboseSearchOptionManager" class="col text-end">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+학생추가</base-button
                  >
                </div>
                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h1>학생</h1>
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row-sm-3 mb-3">
                      <preset-radio
                        v-model="newData.grade"
                        :choices="[
                          ['불교대학', '불교대학'],
                          ['경전대학', '경전대학'],
                        ]"
                      />
                    </div>
                    <div class="row">
                      <base-input
                        v-model="newData.email"
                        type="text"
                        label="구글 계정"
                        name="email"
                        required
                        placeholder="Google Email"
                      />
                    </div>
                    <div class="row">
                      <base-input
                        v-model="newData.memberNo"
                        type="number"
                        required
                        label="회원번호"
                        name="memberNo"
                        placeholder="회원번호"
                      />
                    </div>
                    <div class="row">
                      <base-input
                        v-model="newData.name"
                        type="text"
                        required
                        label="이름"
                        name="name"
                        placeholder="이름"
                      />
                    </div>
                    <div v-if="!add" class="row ps-1 mt2">
                      <div class="col-sm-6">
                        <base-input
                          v-model="newData.diploma"
                          type="text"
                          required
                          label="졸업장번호"
                          name="diploma"
                          placeholder="B012-3-45678"
                        />
                      </div>
                      <div class="col-sm-6" style="padding-top: 38px">
                        <preset-radio
                        v-model="newData.attendType"
                        :choices="[
                          ['개근', '개근'],
                          ['정근', '정근'],
                          ['', '없음'],
                        ]"
                      />
                      </div>
                    </div>
                    <div class="row-sm-3 mb-3">
                      <preset-radio
                        v-model="newData.belong"
                        :choices="[
                          ['주간', '주간'],
                          ['저녁', '저녁'],
                        ]"
                      />
                    </div>
                    <div class="row ps-1">
                      <div class="col-sm-3">
                        <preset-select
                          v-model="newData.local"
                          placeholder="지역"
                          :choices="LOCAL_NAMES_B.map((x) => [x, `${x}`])"
                        />
                      </div>
                      <div class="col-sm-3">
                        <preset-select
                          v-model="newData.room"
                          placeholder="반"
                          :choices="ROOM_NUMBER.map((x) => [x, `${x}반`])"
                        />
                      </div>

                      <div class="col-sm-3">
                        <preset-select
                          v-model="newData.team"
                          placeholder="조"
                          :choices="TEAM_NUMBER.map((x) => [x, `${x}조`])"
                        />
                      </div>
                    </div>
                    <div class="row mt-4">
                      <preset-radio
                        v-model="newData.state"
                        :choices="[ 
                          [ 1, '정상'],
                          [ -11, '과락'],
                          [ -8, '과락(접속허용)'],
                          [ -10, '탈락'],
                          [ -100, '환불'],
                        ]"
                      />
                    </div>

                    <div class="text-center">
                      <base-button
                        v-if="add"
                        type="primary"
                        class="my-4"
                        @click="onAdd"
                        >등록 하기</base-button
                      >
                      <base-button
                        v-else
                        type="primary"
                        class="my-4"
                        @click="onEdit(newData)"
                        >수정 하기</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.local }}</td>
                    <td>{{ row.room }}</td>
                    <td>{{ row.team }}</td>
                    <td>{{ row.belong }}</td>
                    <td>{{ row.name }}</td>
                    <td>{{ row.loginView }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.memberNo }}</td>
                    <td>{{ row.state2 }}</td>
                    <td>
                      <span class="clickable" @click="goDiploma(row)">{{
                        row.diploma
                      }}</span>
                    </td>
                    <td>
                      <span class="clickable" @click="goAward(row)">{{
                        row.attendType
                      }}</span>
                    </td>
                    <td>{{ row.year }}</td>
                    <td>{{ row.semester }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.periodNo }}</td>
                    <td width="10%">
                      <div v-if="isAction" class="d-flex pe-4">
                        <argon-button
                          v-if="verboseSearchOptionBranch2"
                          class="edit me-1"
                          color="warning"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleEdit(row)"
                        >
                          수정
                        </argon-button>
                        <argon-button
                          v-if="verboseSearchOptionMaster"
                          class="remove btn-link"
                          color="danger"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleDelete(row)"
                        >
                          삭제
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- End List -->

            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import {
  ROOM_NUMBER,
  TEAM_NUMBER,
  LOCAL_NAMES_B,
  SEMESTER,
  PRESENT,
} from "@/consts/class";
import { mapState } from "vuex";

export default {
  // 컴포넌트 초기화
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
    PresetRadio,
    Modal,
  },

  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const query = ref({
      team: "",
      room: "",
      local: "",
      name: "",
      state: "",
      email: "",
      memberNo: "",
      bound: 20,
      belong: "",
    });
    const header = ref([
      "No",
      "지 역",
      "반",
      "조",
      "구 분",
      "이 름",
      "로그인",
      "이메일",
      "회원번호",
      "상 태",
      "졸업번호",
      "출 결",
      "입학년도",
      "학 기",
      "학 년",
      "기 수",
      "Action",
    ]);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionManager2 = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionBranch2 = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    let verboseSearchOptionClassLeader2 = ref(false);
    const newData = ref({});
    let modalActive = ref(false);
    const add = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    const originMemberNo = ref(0);
    let isAction = ref(true);
    modalActive.value = false;

    const token = api.Auth.tokenContent;
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      if (year.value == token.year && semester.value == token.semester) {
        isAction.value = true;
      } else {
        isAction.value = false;
      }

      for (const field of ["local", "team", "room"]) {
        if (query.value[field] === "전체") {
          query.value[field] = "";
        }
      }
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }
      const url = `manage/class2/student?bound=${query.value.bound}
   &page=${page}&local=${query.value.local}&room=${query.value.room}&team=${query.value.team}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&name=${query.value.name}&state=${query.value.state}&email=${query.value.email}&memberNo=${query.value.memberNo}&belong=${query.value.belong}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        if (data.rows > 0) {
          for (const row of d) {
            if (row.state === 1) {
              row.state2 = "정상";
            } else if (row.state === -11) {
              row.state2 = "과락";
            } else if (row.state === -8) {
              row.state2 = "과락(접속허용)";
            } else if (row.state === -10) {
              row.state2 = "탈락";
            } else if (row.state === -100) {
              row.state2 = "환불";
            }
            if (row.login === true) {
              row.loginView = "성공";
            } else {
              row.loginView = "X";
            }
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
      verboseSearchOptionManager2.value =
        token.role.includes("manager") ||
        token.role.includes("mentor") ||
        token.role.includes("master");
      verboseSearchOptionBranch2.value =
        token.role.includes("manager") ||
        token.role.includes("branch") ||
        token.role.includes("master");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("mentor") ||
        token.role.includes("observer");
      verboseSearchOptionClassLeader2.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("class leader") ||
        token.role.includes("branch");
      verboseSearchOptionMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const goMeet = (url) => {
      window.open(url);
    };
    const goDiploma = (row) => {
      api.get(`manage/class2/diploma/${row.memberNo}`).then((response) => {
        if (api.isSuccess(response)) {
          setTimeout(function () {
            window.open(response.data.msg, "_blank");
          }, 500);
        }
      });
    };
    const goAward = (row) => {
      api
        .get(`manage/class2/diploma/${row.memberNo}?award=yes`)
        .then((response) => {
          if (api.isSuccess(response)) {
            setTimeout(function () {
              window.open(response.data.msg, "_blank");
            }, 500);
          }
        });
    };
    const closedModal = () => {
      modalActive.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.grade = "";
      newData.value.email = "";
      newData.value.memberNo = "";
      newData.value.name = "";
      newData.value.belong = "";
      newData.value.local = "";
      newData.value.room = "";
      newData.value.team = "";
      newData.value.state = "";
      newData.value.diploma = "";
      newData.value.attendType = "";
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/student/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        originMemberNo.value = response.data.data.memberNo;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      if (newData.value.grade == "") {
        alert("학년을 선택하세요");
        return;
      } else if (newData.value.email == "") {
        alert("이메일을 등록하세요");
        return;
      } else if (newData.value.memberNo == "") {
        alert("회원번호을 등록하세요");
        return;
      } else if (newData.value.name == "") {
        alert("이름을 등록하세요");
        return;
      } else if (newData.value.local == "") {
        alert("지역을 등록하세요");
        return;
      } else if (newData.value.room == "") {
        alert("반을 등록하세요");
        return;
      } else if (newData.value.team == "") {
        alert("조을 등록하세요");
        return;
      }
      const data = JSON.parse(JSON.stringify(newData.value));
      data.email = data.email.trim();
      const url = `manage/class2/student2/${data.memberNo}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        if (response.data.msg == "ok") {
          alert("이미 등록된 회원번호입니다. 확인 부탁드립니다.");
        } else {
          let tempRole = [];
          tempRole.push("student");
          data.role = tempRole; 
          api.post("manage/class2/attend", { data }).then(() => {
            api.post("manage/class2/attend112", { data }).then(() => {
              api.post("manage/class2/student", { data }).then(() => {
                getDataFromApi(1);
                modalActive.value = false;
              });
            });
          }); 
        }
      });
    };
    // 수정
    const onEdit = (row) => {
      if(newData.value.diploma === "" && newData.value.attendType !== ""){
        alert("졸업장번호 없이 개근/정근 입력이 불가 합니다.")
        newData.value.attendType = "";
        return;
      }
      const data2 = JSON.parse(JSON.stringify(newData.value));
      let data = data2;
      api.put(`manage/class2/attendPut/${originMemberNo.value}`, { data }).then(() => {
        api
          .put(`manage/class2/attendPut2/${originMemberNo.value}`, { data })
          .then(() => {           
            api
              .put(`manage/class2/student/${row.id}`, { data })
              .then(() => {
                getDataFromApi(1);
                modalActive.value = false;
              });
          });        
      });
      let today = new Date();
      let year = PRESENT.year;
      let semester = PRESENT.semester;
      console.log("aa: " + year);
      const url2 = `manage/class2/lesson?today=${today}&grade=${data2.grade}&year=${year}&semester=${semester}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data3 = response.data;
        const d = data3.data;
        let lessonNo = d[0].lessonNo + 1;
        if (data2.state == 1) {
          lessonNo = 1;
        }
        const data = {};
        data.state = data2.state;
        data.lessonNo = lessonNo;
        data.grade = data2.grade;
        api
          .put(`manage/class2/attendState2/${row.memberNo}`, { data })
          .then(() => {});
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/student/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        }); 
        api.delete_(`manage/class2/attend/${row.memberNo}`).then(() => {});
        api.delete_(`manage/class2/attend112/${row.memberNo}`).then(() => {});
      }
    };
    return {
      header,
      page2,
      ROOM_NUMBER,
      TEAM_NUMBER,
      LOCAL_NAMES_B,
      SEMESTER,
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionManager2,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      verboseSearchOptionClassLeader2,
      verboseSearchOptionBranch2,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      isAction,
      goDiploma,
      goAward,
      originMemberNo,
    };
  },
  computed: {
    ...mapState(["isRTL", "isNavFixed", "darkMode"]),
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
