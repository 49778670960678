<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">활동가공지</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                  />
                </div>
                <div class="col-sm-2">
                  <preset-select
                    v-model="query.grade"
                    placeholder="학년"
                    size="large"
                    :choices="[
                      ['', '전체'],
                      ['불교대학', '불교대학'],
                      ['경전대학', '경전대학'],
                    ]"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.title"
                    type="text"
                    class="form-control col"
                    placeholder="제목"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col-sm-2">
                  <input
                    v-model="query.post"
                    type="text"
                    class="form-control col"
                    placeholder="내용"
                    @keyup.enter.prevent="getDataFromApi(1)"
                  />
                </div>
                <div class="col">
                  <base-button
                    class="btn-sm-2 mt-3"
                    type="secondary"
                    @click="getDataFromApi(1)"
                    >검색</base-button
                  >
                </div>
                <div v-if="verboseSearchOptionManager" class="col text-end mt-3">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                </div>
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <!-- Preview Modal -->
            <Modal :modal-active="showPreviewModal" @close="closedModal">
              <div class="modal-content">
                <h5 class="ms-4 mt-3">
                  <span class="col-sm-2"> 제 목: </span>
                  <span class="col">
                    {{ previewTitle }}
                  </span>
                </h5>

                <div class="container">
                  <div class="row">
                    <span class="col-sm-1 ps-1 pe-0 ms-2"> 내용: </span>
                    <span class="col ms-0 ps-0">
                      <span
                        v-dompurify-html="previewBody"
                        class="text-left ms-0 ps-0"
                      ></span>
                    </span>
                  </div>
                </div>

                <base-button
                  type="link"
                  class="ml-auto"
                  @click="showPreviewModal = false"
                  >Close</base-button
                >
              </div>
            </Modal>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h2>활동가자료</h2>
                <card type="secondary" body-classes="px-lg-5">
                  <form role="form">
                    <div class="row-sm-3 mt-3">
                      <preset-radio
                        v-model="newData.grade"
                        :choices="[
                          ['전체', '전체'],
                          ['불교대학', '불교대학'],
                          ['경전대학', '경전대학'],
                        ]"
                      />
                    </div>
                    <div class="row">&nbsp;</div>
                    <base-input
                      v-model="newData.title"
                      type="text"
                      label="제목"
                      name="title"
                      size="120"
                    />

                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">내용</label>
                    </div>
                    <!-- <div :key="htmlEditorKey">
                      <html-editor v-model="newData.post" />
                    </div> -->
                    <QuillEditor
                      id="editor"
                      ref="quillEditor"
                      v-model:content="newData.post"
                      content-type="html"
                      theme="snow"
                      toolbar="full"
                      :modules="modules"
                    />
                    <div class="row">&nbsp;</div>
                    <div class="row">
                      <label class="form-control-label">공개여부</label>
                    </div>
                    <div class="row">
                      <preset-radio
                        v-model="newData.state"
                        :choices="[
                          ['1', '공개'],
                          ['0', '비공개'],
                        ]"
                      />
                    </div>
                    <div class="text-center">
                      <base-button
                        v-if="add"
                        type="primary"
                        class="my-4"
                        @click="onAdd(newData)"
                        >등록 하기</base-button
                      >
                      <base-button
                        v-else
                        type="primary"
                        class="my-4"
                        @click="onEdit(newData)"
                        >수정 하기</base-button
                      >
                      <base-button
                        type="secondary"
                        class="my-4 ms-2"
                        @click="closedModal"
                        >닫 기</base-button
                      >
                    </div>
                  </form>
                </card>
              </div>
            </Modal>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td width="30%">
                      <span class="clickable" @click="showPreview(row)">
                        <div
                          class="font-weight-600"
                          style="color: cornflowerblue"
                        >
                          {{ row.title }}
                        </div>
                      </span>
                    </td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.createdTime }}</td>
                    <td>
                      <span :class="row.statusClass">● </span>{{ row.status }}
                    </td>
                    <td v-if="verboseSearchOptionManager" width="10%">
                      <div class="d-flex pe-4">
                        <base-button
                          class="edit me-1"
                          type="warning"
                          size="sm"
                          icon
                          @click.stop="handleEdit(row)"
                        >
                          <i class="text-white fa fa-pen" />
                        </base-button>
                        <base-button
                          class="remove btn-link"
                          type="danger"
                          size="sm"
                          icon
                          @click.stop="handleDelete(row)"
                        >
                          <i class="text-white fas fa-trash-alt"></i>
                        </base-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import moment from "moment";
import { SEMESTER } from "@/consts/class";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
    QuillEditor,
  },
  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref(["No", "제 목", "학 년", "날 짜", "공 개", "Action"]);
    const newData = ref({ post: "" });
    let previewTitle = ref("");
    let previewBody = ref("");
    let showPreviewModal = ref(false);
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    let modalActive = ref(false);
    const add = ref(false);
    const query = ref({
      grade: "",
      post: "",
      title: "",
      bound: "",
    });
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    const modules = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("filelist", file);
            axios
              .post(`${process.env.VUE_APP_API_BASE_URL}/imagesave`, formData)
              .then((res) => {
                console.log(res);
                resolve(res.data.url);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    };
    const quillEditorRef = ref(null);
    const someText = ref("");
    const setEditorText = () => {
      someText.value = "";
      quillEditorRef.value.setHTML(someText.value);
    };

    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      const url = `manage/class2/noticeManager?bound=${query.value.bound}&page=${page}&title=${query.value.title}&post=${query.value.post}&grade=${query.value.grade}&year=${year.value}&semester=${semester.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            updateRowStatus(row, row.state);
            row.createdTime = moment(row.createdTime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") || token.role.includes("master");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);

    const updateRowStatus = (row, state) => {
      if (state === 1) {
        row.status = "공개";
        row.statusClass = "text-success";
      } else {
        row.status = "비공개";
        row.statusClass = "text-danger";
      }
    };
    const showPreview = (row) => {
      previewTitle.value = row.title;
      previewBody.value = row.post;
      showPreviewModal.value = true;
    };
    const goMeet = (url) => {
      window.open(url);
    };
    const closedModal = () => {
      modalActive.value = false;
      showPreviewModal.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.title = "";
      newData.value.post = "";
      newData.value.state = "";
      newData.value.grade = "";
      setEditorText();
      add.value = true;
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/noticeManager/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        newData.value = response.data.data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/noticeManager", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/noticeManager/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/noticeManager/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      quillEditor: quillEditorRef,
      header,
      page2,
      SEMESTER,
      newData,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      handleEdit,
      onAdd,
      onEdit,
      handleAdd,
      handleDelete,
      previewBody,
      previewTitle,
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      modalActive,
      add,
      showPreview,
      showPreviewModal,
      closedModal,
      modules,
    };
  },
  // created() {
  //   // 페이지 생성 시 호출
  //   const token = api.Auth.tokenContent;
  //   this.verboseSearchOptionManager =
  //     token.role.includes("manager") ||
  //     token.role.includes("master") ||
  //     token.role.includes("mentor");
  //   this.verboseSearchOptionBranch =
  //     token.role.includes("manager") ||
  //     token.role.includes("master") ||
  //     token.role.includes("observer") ||
  //     token.role.includes("mentor") ||
  //     token.role.includes("branch");
  //   this.verboseSearchOptionClassLeader =
  //     token.role.includes("manager") ||
  //     token.role.includes("master") ||
  //     token.role.includes("mentor") ||
  //     token.role.includes("class leader") ||
  //     token.role.includes("branch") ||
  //     token.role.includes("observer");
  //   this.verboseSearchOptionMaster =
  //     token.role.includes("master") || token.role.includes("manager");
  //   this.verboseSearchOptionSemester =
  //     token.role.includes("master") ||
  //     token.role.includes("observer") ||
  //     token.role.includes("manager") ||
  //     token.role.includes("mentor");
  // },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
<style>
img {
  max-width: 100%;
}
</style>
