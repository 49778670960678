<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">
            전국수업통계 (회차별)
          </h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER2.map((x) => [x, `${x}`])"
                    @change="gradeChange()"
                  />
                </div>
                <div v-if="verboseSearchOptionManager" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.local"
                    placeholder="지부"
                    size="large"
                    :choices="LOCAL4.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-if="verboseSearchOptionBranch" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.lessonType"
                    placeholder="수업타입"
                    size="large"
                    :choices="Object.entries(LESSON_TYPES)"
                    @change="lessonTypeChange()"
                  />
                </div>
                <div v-if="bsection == '불교대학'" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="과목"
                    size="large"
                    :choices="BLESSON_NAMES.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-else-if="bsection == '경전대학'" class="col-sm-2 mb-3">
                  <preset-select
                    v-model="query.lessonName"
                    placeholder="과목"
                    size="large"
                    :choices="KLESSON_NAMES.map((x) => [x, `${x}`])"
                  />
                </div>
                <div v-else class="col-sm-2 mb-3">
                  <input
                    v-model="query.lessonNo"
                    label="수업번호"
                    type="number"
                    class="form-control col"
                    placeholder="수업번호"
                    @change.prevent="search()"
                  />
                </div>

                <div v-if="verboseSearchOptionClassLeader" class="col-sm-2">
                  <base-button class="btn-sm" type="secondary" @click="search()"
                    >검색</base-button
                  >
                </div>

                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row mt-2">
              <div class="col-sm-10 text-center">
                수업번호 : {{ lessonNo2 }}, 과목 : {{ lessonName }}
              </div>
              <div class="col-sm-1 text-end">
                <!-- New Button -->
                <base-button
                  v-if="verboseSearchOptionBranch"
                  type="primary"
                  class="btn-sm"
                  @click="googleSave()"
                  >google</base-button
                >
                <!-- End New Button -->
              </div>
              <div class="col-sm-1 text-end">
                <!-- New Button -->
                <base-button type="primary" class="btn-sm" @click="excelSave()"
                  >excel</base-button
                >
                <!-- End New Button -->
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td v-if="queryLocal">{{ row.local2 }}</td>
                    <td v-else>{{ row.local2 }}</td>
                    <td>{{ row.sumTotal }}</td>
                    <td>{{ row.sumAttend }}</td>
                    <td>{{ row.sumLate }}</td>
                    <td>{{ row.sumEarly }}</td>
                    <td>{{ row.sumAbscent }}</td>
                    <td>{{ row.total }}</td>
                    <td>{{ row.attend3 }}</td>
                    <td>{{ row.late3 }}</td>
                    <td>{{ row.early3 }}</td>
                    <td>{{ row.abscent3 }}</td>
                    <td>{{ row.total2 }}</td>
                    <td>{{ row.attend4 }}</td>
                    <td>{{ row.late4 }}</td>
                    <td>{{ row.early4 }}</td>
                    <td>{{ row.abscent4 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import { ElTable, ElTableColumn } from "element-plus";
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
// import moment from "moment-timezone";
import {
  ROOM_NUMBER,
  TEAM_NUMBER,
  LOCAL3,
  LOCAL4,
  BLESSON_NAMES,
  KLESSON_NAMES,
  LESSON_TYPES,
  SEMESTER2,
} from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    PresetSelect,
    //Modal,
  },
  setup() {
    let tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    let queryLocal = ref("");
    let bsection = ref("");
    // let ksection = ref(false);
    let header = ref([
      "No",
      "지부",
      "총재적인원",
      "총출석",
      "총지각",
      "총조퇴",
      "총결석",
      "주간재적인원",
      "주간출석",
      "주간지각",
      "주간조퇴",
      "주간결석",
      "저녁재적인원",
      "저녁출석",
      "저녁지각",
      "저녁조퇴",
      "저녁결석",
    ]);
    if (queryLocal.value) {
      header = ref([
        "No",
        "반",
        "총재적인원",
        "총출석",
        "총지각",
        "총조퇴",
        "총결석",
        "주간재적인원",
        "주간출석",
        "주간지각",
        "주간조퇴",
        "주간결석",
        "저녁재적인원",
        "저녁출석",
        "저녁지각",
        "저녁조퇴",
        "저녁결석",
      ]);
    }
    const query = ref({
      semester: "",
      local: "",
      room: "",
      lessonType: "online",
      lessonNo: "",
      lessonName: "",
    });
    let verboseSearchOptionManager = ref(false);
    let verboseSearchOptionBranch = ref(false);
    let verboseSearchOptionMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    let verboseSearchOptionClassLeader = ref(false);
    const newData = ref({});
    let modalActive = ref(false);
    let semester = "";
    let year = 0;
    let grade = "";
    modalActive.value = false;
    //let isClassDay = false;
    let lessonNo2 = ref(0);
    let lessonNo = 0;
    let jeon = "";
    let roomTotal = 0;
    let local = "";
    let lessonName = ref("");

    const token = api.Auth.tokenContent;

    if (query.value.semester === "") {
      query.value.grade = token.grade;
    }

    // let now = moment();
    // moment.tz.setDefault("Asia/Seoul");
    //let date = now.format("2023-04-06 04:00:00");
    // let date = now.format("YYYY-MM-DD");
    // let diffDay = "";
    // let tempA = 0;
    // if (query.value.grade === "불교대학") {
    //   diffDay = moment("2023-09-12 04:00:00").diff(date, "hours") * -1;
    //   tempA = parseInt(diffDay / 168) + 1;
    //   query.value.lessonNo = tempA;
    // } else {
    //   diffDay = moment("2023-09-12 04:00:00").diff(date, "hours") * -1;
    //   tempA = parseInt(diffDay / 168) + 1;
    //   query.value.lessonNo = tempA;
    // }
    const lessonTypeChange = () => {
      if (query.value.lessonType == "talk") {
        bsection.value = "talk";
      } else if (query.value.lessonType == "offline") {
        bsection.value = "offline";
      } else if (query.value.lessonType == "online") {
        if (grade == "경전대학") {
          bsection.value = "경전대학";
        } else if (grade == "불교대학") {
          bsection.value = "불교대학";
        }
      }
    };
    const gradeChange = () => {
      const temp = query.value.semester.split(" ");
      grade = temp[0];
      if (grade == "경전대학") {
        bsection.value = "경전대학";
        query.value.lessonName = "입학식";
      } else if (grade == "불교대학") {
        bsection.value = "불교대학";
        query.value.lessonName = "입학식";
      }
    };

    const getDataFromApi = () => {
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        year = temp[1];
        semester = temp[2];
        grade = temp[0];
      } else {
        year = token.year;
        semester = token.semester;
        grade = token.grade;
        if (grade == "경전대학") {
          bsection.value = "경전대학";
        } else if (grade == "불교대학") {
          bsection.value = "불교대학";
        }
      }

      // let todayOfWeek = now.format("e");
      // if (todayOfWeek == 2 || todayOfWeek == 4) {
      //   let start1 = moment(date).add(19, "hours");
      //   let start2 = moment(start1).add(35, "minutes");
      //   let end1 = moment(date).add(20, "hours");
      //   let end2 = moment(end1).add(5, "minutes");
      //   if (now >= start2 && now < end2) {
      //     isClassDay = true;
      //   } else {
      //     isClassDay = false;
      //   }
      // }
      // if (isClassDay === true) {
      //   alert("화요일, 목요일 19시 35분부터 20시 5분까지는 이용이 불가합니다.");
      //   return;
      // }
      let newLocal = [];
      // if (query.value.semester) {
      //   const temp = query.value.semester.split(" ");
      //   grade = temp[0];
      //   year = temp[1];
      //   semester = temp[2];
      // } else {
      //   grade = token.grade;
      //   year = token.year;
      //   semester = token.semester;
      // }

      const url22 = `manage/class2/attendStats33?grade=${grade}&year=${year}&semester=${semester}&state=1`;
      api.get(url22).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        let room1 = 0;
        let room2 = 0;
        let room3 = 0;
        let room4 = 0;
        let room5 = 0;
        let room6 = 0;
        let room7 = 0;
        let room8 = 0;
        let room9 = 0;
        let room10 = 0;
        let room11 = 0;
        // let room12 = 0;

        let team1 = 0;
        let team2 = 0;
        let team3 = 0;
        let team4 = 0;
        let team5 = 0;
        let team6 = 0;
        let team7 = 0;
        let team8 = 0;
        let team9 = 0;
        let team10 = 0;
        let team11 = 0;

        for (const row of data.data) {
          if (LOCAL3[1] === row.id.local) {
            room1 = room1 + 1;
            team1 = team1 + row.count;
          } else if (LOCAL3[2] === row.id.local) {
            room2 = room2 + 1;
            team2 = team2 + row.count;
          } else if (LOCAL3[3] === row.id.local) {
            room3 = room3 + 1;
            team3 = team3 + row.count;
          } else if (LOCAL3[4] === row.id.local) {
            room4 = room4 + 1;
            team4 = team4 + row.count;
          } else if (LOCAL3[5] === row.id.local) {
            room5 = room5 + 1;
            team5 = team5 + row.count;
          } else if (LOCAL3[6] === row.id.local) {
            room6 = room6 + 1;
            team6 = team6 + row.count;
          } else if (LOCAL3[7] === row.id.local) {
            room7 = room7 + 1;
            team7 = team7 + row.count;
          } else if (LOCAL3[8] === row.id.local) {
            room8 = room8 + 1;
            team8 = team8 + row.count;
          } else if (LOCAL3[9] === row.id.local) {
            room9 = room9 + 1;
            team9 = team9 + row.count;
          } else if (LOCAL3[10] === row.id.local) {
            room10 = room10 + 1;
            team10 = team10 + row.count;
          } else if (LOCAL3[11] === row.id.local) {
            room11 = room11 + 1;
            team11 = team11 + row.count;
          }
        }

        newLocal.push("aa");
        if (grade === "경전대학") {
          newLocal.push(LOCAL3[1] + "(" + room1 + "/" + team1 + ")");
        } else {
          // room12 = 2;
          newLocal.push(LOCAL3[1] + "(" + room1 + "/" + team1 + ")");
        }
        newLocal.push(LOCAL3[2] + "(" + room2 + "/" + team2 + ")");
        newLocal.push(LOCAL3[3] + "(" + room3 + "/" + team3 + ")");
        newLocal.push(LOCAL3[4] + "(" + room4 + "/" + team4 + ")");
        newLocal.push(LOCAL3[5] + "(" + room5 + "/" + team5 + ")");
        newLocal.push(LOCAL3[6] + "(" + room6 + "/" + team6 + ")");
        newLocal.push(LOCAL3[7] + "(" + room7 + "/" + team7 + ")");
        newLocal.push(LOCAL3[8] + "(" + room8 + "/" + team8 + ")");
        newLocal.push(LOCAL3[9] + "(" + room9 + "/" + team9 + ")");
        newLocal.push(LOCAL3[10] + "(" + room10 + "/" + team10 + ")");
        if (grade === "불교대학") {
          newLocal.push(LOCAL3[11] + "(" + room11 + "/" + team11 + ")");
        }

        const roomSum =
          room1 +
          room2 +
          room3 +
          room4 +
          room5 +
          room6 +
          room7 +
          room8 +
          room9 +
          room10 +
          room11;
        const teamSum =
          team1 +
          team2 +
          team3 +
          team4 +
          team5 +
          team6 +
          team7 +
          team8 +
          team9 +
          team10 +
          team11;
        newLocal.push("전체" + "(" + roomSum + "/" + teamSum + ")");

        // let diffDay = moment("2023-04-02 10:00:00").diff(date, "hours") * -1;
        // let tempA = parseInt(diffDay / 168) + 2;
        // if (grade === "경전대학") {
        //   diffDay = moment("2023-03-25 10:00:00").diff(date, "hours") * -1;
        //   tempA = parseInt(diffDay / 168) + 1;
        // }

        // if (now < moment("2023-04-02 20:00:00") && grade === "불교대학") {
        //   tempA = 1;
        // } else if (
        //   now < moment("2023-03-25 20:00:00") &&
        //   grade === "경전대학"
        // ) {
        //   tempA = 1;
        // }
        let start = localStorage.getItem("start", ""); 
        let url2 = `manage/class2/lesson?today=today&grade=${grade}&year=${year}&semester=${semester}&start=${start}`;

        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data5 = response.data;
          const d5 = data5.data;
          lessonNo = d5[0].lessonNo;

          if (lessonNo < 1) {
            lessonNo = 1;
          }
          if (query.value.lessonNo) {
            lessonNo = Number(query.value.lessonNo);
          }
          if (query.value.lessonType == "online" && lessonNo > 19) {
            lessonNo = 19;
          }
          tableData.value = [];
          if (query.value.lessonType === "online") {
            if (query.value.lessonNo) {
              if (grade === "불교대학") {
                lessonName.value = BLESSON_NAMES[lessonNo - 1];
              } else if (grade === "경전대학") {
                lessonName.value = KLESSON_NAMES[lessonNo - 1];
              }
            } else {
              if (grade === "불교대학") {
                lessonName.value = BLESSON_NAMES[lessonNo - 1];
              } else if (grade === "경전대학") {
                lessonName.value = KLESSON_NAMES[lessonNo - 1];
              }
            }
          } else if (query.value.lessonType === "talk") {
            if (query.value.lessonNo) {
              lessonNo = Number(query.value.lessonNo);
              if (lessonNo > 3) {
                lessonNo = 3;
              }
              lessonName.value = "즉문즉설" + lessonNo;
            } else {
              lessonName.value = "즉문즉설1";
            }
          } else if (query.value.lessonType === "offline") {
            if (query.value.lessonNo) {
              lessonNo = Number(query.value.lessonNo);
              if (lessonNo > 4) {
                lessonNo = 4;
              }
              lessonName.value = "실천활동" + lessonNo;
            } else {
              lessonName.value = "실천활동1";
            }
          }
          if (query.value.lessonName && query.value.lessonType === "online") {
            if (grade == "경전대학") {
              lessonNo = KLESSON_NAMES.indexOf(query.value.lessonName) + 1;
              lessonName.value = query.value.lessonName;
            } else if (grade === "불교대학") {
              lessonName.value = query.value.lessonName;
              lessonNo = BLESSON_NAMES.indexOf(query.value.lessonName) + 1;
            }
          }

          lessonNo2.value = lessonNo;
          if (grade === "경전대학") {
            const url = `manage/class2/attendStatsTotal1?&grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
            api.get(url).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data = response.data;
              const d = data.data;
              const aa1 = {};
              const aa2 = {};
              const aa3 = {};
              const aa4 = {};
              const aa5 = {};
              const aa6 = {};
              const aa7 = {};
              const aa8 = {};
              const aa9 = {};
              const aa10 = {};
              const aa11 = {};

              const a11 = [
                aa1,
                aa2,
                aa3,
                aa4,
                aa5,
                aa6,
                aa7,
                aa8,
                aa9,
                aa10,
                aa11,
              ];
              for (let i = 0; i < 11; i++) {
                a11[i].no = i + 1;
                a11[i].local = LOCAL3[i + 1];
                a11[i].local2 = newLocal[i + 1];
                a11[i].attend = 0;
                a11[i].late = 0;
                a11[i].early = 0;
                a11[i].abscent = 0;
                a11[i].total = 0;
                a11[i].attend2 = 0;
                a11[i].late2 = 0;
                a11[i].early2 = 0;
                a11[i].abscent2 = 0;
                a11[i].total2 = 0;
              }

              let attendTotal = 0;
              let lateTotal = 0;
              let earlyTotal = 0;
              let abscentTotal = 0;
              let attendTotal2 = 0;
              let lateTotal2 = 0;
              let earlyTotal2 = 0;
              let abscentTotal2 = 0;

              if (data.rows > 0) {
                for (const row of d) {
                  for (let i = 0; i < 10; i++) {
                    if (a11[i].local === row.id.local) {
                      if (row.id.belong === "주간") {
                        if (row.id.attend === 1) {
                          a11[i].attend = row.count;
                          attendTotal = attendTotal + row.count;
                        } else if (row.id.attend === -1) {
                          a11[i].late = row.count;
                          lateTotal = lateTotal + row.count;
                        } else if (row.id.attend === -2) {
                          a11[i].early = row.count;
                          earlyTotal = earlyTotal + row.count;
                        } else if (row.id.attend === 0) {
                          a11[i].abscent = row.count;
                          abscentTotal = abscentTotal + row.count;
                        }
                        a11[i].total =
                          a11[i].attend +
                          a11[i].late +
                          a11[i].early +
                          a11[i].abscent;
                        a11[i].attend3 =
                          a11[i].attend +
                          "(" +
                          a11[i].attend / a11[i].total +
                          ")";
                        a11[i].attend3 =
                          a11[i].attend +
                          "(" +
                          ((a11[i].attend / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].late3 =
                          a11[i].late +
                          "(" +
                          ((a11[i].late / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].early3 =
                          a11[i].early +
                          "(" +
                          ((a11[i].early / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].abscent3 =
                          a11[i].abscent +
                          "(" +
                          ((a11[i].abscent / a11[i].total) * 100).toFixed(1) +
                          ")";
                      } else {
                        if (row.id.attend === 1) {
                          a11[i].attend2 = row.count;
                          attendTotal2 = attendTotal2 + row.count;
                        } else if (row.id.attend === -1) {
                          a11[i].late2 = row.count;
                          lateTotal2 = lateTotal2 + row.count;
                        } else if (row.id.attend === -2) {
                          a11[i].early2 = row.count;
                          earlyTotal2 = earlyTotal2 + row.count;
                        } else if (row.id.attend === 0) {
                          a11[i].abscent2 = row.count;
                          abscentTotal2 = abscentTotal2 + row.count;
                        }
                        a11[i].total2 =
                          a11[i].attend2 +
                          a11[i].late2 +
                          a11[i].early2 +
                          a11[i].abscent2;
                        a11[i].attend4 =
                          a11[i].attend2 +
                          "(" +
                          ((a11[i].attend2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].late4 =
                          a11[i].late2 +
                          "(" +
                          ((a11[i].late2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].early4 =
                          a11[i].early2 +
                          "(" +
                          ((a11[i].early2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].abscent4 =
                          a11[i].abscent2 +
                          "(" +
                          ((a11[i].abscent2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                      }
                      a11[i].sumTotal = a11[i].total + a11[i].total2;
                      a11[i].sumAttend =
                        a11[i].attend +
                        a11[i].attend2 +
                        "(" +
                        (
                          ((a11[i].attend + a11[i].attend2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumLate =
                        a11[i].late +
                        a11[i].late2 +
                        "(" +
                        (
                          ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumEarly =
                        a11[i].early +
                        a11[i].early2 +
                        "(" +
                        (
                          ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumAbscent =
                        a11[i].abscent +
                        a11[i].abscent2 +
                        "(" +
                        (
                          ((a11[i].abscent + a11[i].abscent2) /
                            a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                    }
                  }
                }
              }

              aa11.local = "전국";
              aa11.total = attendTotal + earlyTotal + lateTotal + abscentTotal;
              aa11.attend3 =
                attendTotal +
                "(" +
                ((attendTotal / aa11.total) * 100).toFixed(1) +
                ")";
              aa11.late3 =
                lateTotal +
                "(" +
                ((lateTotal / aa11.total) * 100).toFixed(1) +
                ")";
              aa11.abscent3 =
                abscentTotal +
                "(" +
                ((abscentTotal / aa11.total) * 100).toFixed(1) +
                ")";
              aa11.early3 =
                earlyTotal +
                "(" +
                ((earlyTotal / aa11.total) * 100).toFixed(1) +
                ")";
              aa11.total2 =
                attendTotal2 + earlyTotal2 + lateTotal2 + abscentTotal2;
              aa11.attend4 =
                attendTotal2 +
                "(" +
                ((attendTotal2 / aa11.total2) * 100).toFixed(1) +
                ")";
              aa11.late4 =
                lateTotal2 +
                "(" +
                ((lateTotal2 / aa11.total2) * 100).toFixed(1) +
                ")";
              aa11.abscent4 =
                abscentTotal2 +
                "(" +
                ((abscentTotal2 / aa11.total2) * 100).toFixed(1) +
                ")";
              aa11.early4 =
                earlyTotal2 +
                "(" +
                ((earlyTotal2 / aa11.total2) * 100).toFixed(1) +
                ")";

              aa11.sumTotal = aa11.total + aa11.total2;
              aa11.sumAttend =
                attendTotal +
                attendTotal2 +
                "(" +
                (((attendTotal + attendTotal2) / aa11.sumTotal) * 100).toFixed(
                  1
                ) +
                ")";
              aa11.sumLate =
                lateTotal +
                lateTotal2 +
                "(" +
                (((lateTotal + lateTotal2) / aa11.sumTotal) * 100).toFixed(1) +
                ")";
              aa11.sumEarly =
                earlyTotal +
                earlyTotal2 +
                "(" +
                (((earlyTotal + earlyTotal2) / aa11.sumTotal) * 100).toFixed(
                  1
                ) +
                ")";
              aa11.sumAbscent =
                abscentTotal +
                abscentTotal2 +
                "(" +
                (
                  ((abscentTotal + abscentTotal2) / aa11.sumTotal) *
                  100
                ).toFixed(1) +
                ")";
              tableData.value = a11;
            });
          } else {
            const url = `manage/class2/attendStatsTotal21?grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
            api.get(url).then((response2) => {
              if (!api.isSuccess(response2)) {
                return;
              }
              const data2 = response2.data;
              const d2 = data2.data;

              const url = `manage/class2/attendStatsTotal1?grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
              api.get(url).then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                const data = response.data;
                const d = data.data;
                const aa1 = {};
                const aa2 = {};
                const aa3 = {};
                const aa4 = {};
                const aa5 = {};
                const aa6 = {};
                const aa7 = {};
                const aa8 = {};
                const aa9 = {};
                const aa10 = {};
                const aa11 = {};
                const aa12 = {};

                const a11 = [
                  aa1,
                  aa2,
                  aa3,
                  aa4,
                  aa5,
                  aa6,
                  aa7,
                  aa8,
                  aa9,
                  aa10,
                  aa11,
                  aa12,
                ];
                for (let i = 0; i < 12; i++) {
                  a11[i].no = i + 1;
                  a11[i].local = LOCAL4[i + 1];
                  a11[i].local2 = newLocal[i + 1];
                  a11[i].attend = 0;
                  a11[i].late = 0;
                  a11[i].early = 0;
                  a11[i].abscent = 0;
                  a11[i].total = 0;
                  a11[i].attend2 = 0;
                  a11[i].late2 = 0;
                  a11[i].early2 = 0;
                  a11[i].abscent2 = 0;
                  a11[i].total2 = 0;
                }

                let attendTotal = 0;
                let lateTotal = 0;
                let earlyTotal = 0;
                let abscentTotal = 0;
                let attendTotal2 = 0;
                let lateTotal2 = 0;
                let earlyTotal2 = 0;
                let abscentTotal2 = 0;

                if (data.rows > 0) {
                  for (const row of d) {
                    for (let i = 0; i < 11; i++) {
                      if (a11[i].local === row.id.local) {
                        if (row.id.belong === "주간") {
                          if (row.id.attend === 1) {
                            a11[i].attend = row.count;
                            attendTotal = attendTotal + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late = row.count;
                            lateTotal = lateTotal + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early = row.count;
                            earlyTotal = earlyTotal + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent = row.count;
                            abscentTotal = abscentTotal + row.count;
                          }
                          a11[i].total =
                            a11[i].attend +
                            a11[i].late +
                            a11[i].early +
                            a11[i].abscent;
                          a11[i].attend3 =
                            a11[i].attend +
                            "(" +
                            ((a11[i].attend / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].late3 =
                            a11[i].late +
                            "(" +
                            ((a11[i].late / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].early3 =
                            a11[i].early +
                            "(" +
                            ((a11[i].early / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent3 =
                            a11[i].abscent +
                            "(" +
                            ((a11[i].abscent / a11[i].total) * 100).toFixed(1) +
                            ")";
                        } else {
                          if (row.id.attend === 1) {
                            a11[i].attend2 = row.count;
                            attendTotal2 = attendTotal2 + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late2 = row.count;
                            lateTotal2 = lateTotal2 + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early2 = row.count;
                            earlyTotal2 = earlyTotal2 + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent2 = row.count;
                            abscentTotal2 = abscentTotal2 + row.count;
                          }
                          a11[i].total2 =
                            a11[i].attend2 +
                            a11[i].late2 +
                            a11[i].early2 +
                            a11[i].abscent2;
                          a11[i].attend4 =
                            a11[i].attend2 +
                            "(" +
                            ((a11[i].attend2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                          a11[i].late4 =
                            a11[i].late2 +
                            "(" +
                            ((a11[i].late2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].early4 =
                            a11[i].early2 +
                            "(" +
                            ((a11[i].early2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent4 =
                            a11[i].abscent2 +
                            "(" +
                            ((a11[i].abscent2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                        }
                        a11[i].sumTotal = a11[i].total + a11[i].total2;
                        a11[i].sumAttend =
                          a11[i].attend +
                          a11[i].attend2 +
                          "(" +
                          (
                            ((a11[i].attend + a11[i].attend2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumLate =
                          a11[i].late +
                          a11[i].late2 +
                          "(" +
                          (
                            ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumEarly =
                          a11[i].early +
                          a11[i].early2 +
                          "(" +
                          (
                            ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumAbscent =
                          a11[i].abscent +
                          a11[i].abscent2 +
                          "(" +
                          (
                            ((a11[i].abscent + a11[i].abscent2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                      }
                    }
                  }
                }
                if (data2.rows > 0) {
                  for (const row2 of d2) {
                    if (row2.id.belong === "주간") {
                      if (row2.id.attend === 1) {
                        aa11.attend = aa11.attend + row2.count;
                      } else if (row2.id.attend === -1) {
                        aa11.late = aa11.late + row2.count;
                      } else if (row2.id.attend === -2) {
                        aa11.early = aa11.early + row2.count;
                      } else if (row2.id.attend === 0) {
                        aa11.abscent = aa11.abscent + row2.count;
                      }
                    } else {
                      if (row2.id.attend === 1) {
                        aa11.attend2 = aa11.attend2 + row2.count;
                      } else if (row2.id.attend === -1) {
                        aa11.late2 = aa11.late2 + row2.count;
                      } else if (row2.id.attend === -2) {
                        aa11.early2 = aa11.early2 + row2.count;
                      } else if (row2.id.attend === 0) {
                        aa11.abscent2 = aa11.abscent2 + row2.count;
                      }
                    }
                  }
                }
                aa11.total =
                  aa11.attend + aa11.late + aa11.early + aa11.abscent;
                aa11.total2 =
                  aa11.attend2 + aa11.late2 + aa11.early2 + aa11.abscent2;

                aa11.sumTotal = aa11.total + aa11.total2;
                aa11.sumAttend =
                  aa11.attend +
                  aa11.attend2 +
                  "(" +
                  (
                    ((aa11.attend + aa11.attend2) / aa11.sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                aa11.sumLate =
                  aa11.late +
                  aa11.late2 +
                  "(" +
                  (
                    ((aa11.attend + aa11.attend2) / aa11.sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                aa11.sumEarly =
                  aa11.early +
                  aa11.early2 +
                  "(" +
                  (((aa11.early + aa11.early2) / aa11.sumTotal) * 100).toFixed(
                    1
                  ) +
                  ")";
                aa11.sumAbscent =
                  aa11.abscent +
                  aa11.abscent2 +
                  "(" +
                  (
                    ((aa11.abscent + aa11.abscent2) / aa11.sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";

                // aa1.attend3 =
                //   aa1.attend +
                //   "(" +
                //   ((aa1.attend / aa1.total) * 100).toFixed(1) +
                //   ")";
                // aa1.late3 =
                //   aa1.late +
                //   "(" +
                //   ((aa1.late / aa1.total) * 100).toFixed(1) +
                //   ")";
                // aa1.early3 =
                //   aa1.early +
                //   "(" +
                //   ((aa1.early / aa1.total) * 100).toFixed(1) +
                //   ")";
                // aa1.abscent3 =
                //   aa1.abscent +
                //   "(" +
                //   ((aa1.abscent / aa1.total) * 100).toFixed(1) +
                //   ")";

                // aa1.attend4 =
                //   aa1.attend2 +
                //   "(" +
                //   ((aa1.attend2 / aa1.total2) * 100).toFixed(1) +
                //   ")";
                // aa1.late4 =
                //   aa1.late2 +
                //   "(" +
                //   ((aa1.late2 / aa1.total2) * 100).toFixed(1) +
                //   ")";
                // aa1.early4 =
                //   aa1.early2 +
                //   "(" +
                //   ((aa1.early2 / aa1.total2) * 100).toFixed(1) +
                //   ")";
                // aa1.abscent4 =
                //   aa1.abscent2 +
                //   "(" +
                //   ((aa1.abscent2 / aa1.total2) * 100).toFixed(1) +
                //   ")";

                aa12.local = "전국";
                aa12.total =
                  attendTotal + earlyTotal + lateTotal + abscentTotal;
                aa12.attend3 =
                  attendTotal +
                  "(" +
                  ((attendTotal / aa12.total) * 100).toFixed(1) +
                  ")";
                aa12.late3 =
                  lateTotal +
                  "(" +
                  ((lateTotal / aa12.total) * 100).toFixed(1) +
                  ")";
                aa12.abscent3 =
                  abscentTotal +
                  "(" +
                  ((abscentTotal / aa12.total) * 100).toFixed(1) +
                  ")";
                aa12.early3 =
                  earlyTotal +
                  "(" +
                  ((earlyTotal / aa12.total) * 100).toFixed(1) +
                  ")";
                aa12.total2 =
                  attendTotal2 + earlyTotal2 + lateTotal2 + abscentTotal2;
                aa12.attend4 =
                  attendTotal2 +
                  "(" +
                  ((attendTotal2 / aa12.total2) * 100).toFixed(1) +
                  ")";
                aa12.late4 =
                  lateTotal2 +
                  "(" +
                  ((lateTotal2 / aa12.total2) * 100).toFixed(1) +
                  ")";
                aa12.abscent4 =
                  abscentTotal2 +
                  "(" +
                  ((abscentTotal2 / aa12.total2) * 100).toFixed(1) +
                  ")";
                aa12.early4 =
                  earlyTotal2 +
                  "(" +
                  ((earlyTotal2 / aa12.total2) * 100).toFixed(1) +
                  ")";
                aa12.sumTotal = aa12.total + aa12.total2;
                aa12.sumAttend =
                  attendTotal +
                  attendTotal2 +
                  "(" +
                  (
                    ((attendTotal + attendTotal2) / aa12.sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                aa12.sumLate =
                  lateTotal +
                  lateTotal2 +
                  "(" +
                  (((lateTotal + lateTotal2) / aa12.sumTotal) * 100).toFixed(
                    1
                  ) +
                  ")";
                aa12.sumEarly =
                  earlyTotal +
                  earlyTotal2 +
                  "(" +
                  (((earlyTotal + earlyTotal2) / aa12.sumTotal) * 100).toFixed(
                    1
                  ) +
                  ")";
                aa12.sumAbscent =
                  abscentTotal +
                  abscentTotal2 +
                  "(" +
                  (
                    ((abscentTotal + abscentTotal2) / aa12.sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                tableData.value = a11;
              });
            });
          }
        });
      });
    };
    //지부별 통계
    const getData = () => {
      let newLocal = [];
      const token = api.Auth.tokenContent;

      //grade = token.grade;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade = temp[0];
        year = temp[1];
        semester = temp[2];
      } else {
        grade = token.grade;
        year = token.year;
        semester = token.semester;
      }
      if (
        query.value.semester &&
        (token.role === "manager" || token.role === "master")
      ) {
        const temp = query.value.semester.split(" ");
        grade = temp[0];
        year = temp[1];
        semester = temp[2];
      }

      local = query.value.local;

      if (query.value.local === "전국반") {
        local = "강원경기동부";
        jeon = "전국반";
      } else {
        jeon = "";
      }

      const url22 = `manage/class2/attendStats5?local=${local}&grade=${grade}&year=${year}&semester=${semester}&state=1`;
      api.get(url22).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        roomTotal = data.total;

        let team1 = 0;
        let team2 = 0;
        let team3 = 0;
        let team4 = 0;
        let team5 = 0;
        let team6 = 0;
        let team7 = 0;
        let team8 = 0;
        let team9 = 0;
        let team10 = 0;
        let team11 = 0;
        let team12 = 0;

        for (const row of data.data) {
          if (row.id.room === 1) {
            team1 = team1 + row.count;
          } else if (row.id.room === 2) {
            team2 = team2 + row.count;
          } else if (row.id.room === 3) {
            team3 = team3 + row.count;
          } else if (row.id.room === 4) {
            team4 = team4 + row.count;
          } else if (row.id.room === 5) {
            team5 = team5 + row.count;
          } else if (row.id.room === 6) {
            team6 = team6 + row.count;
          } else if (row.id.room === 7) {
            team7 = team7 + row.count;
          } else if (row.id.room === 8) {
            team8 = team8 + row.count;
          } else if (row.id.room === 9) {
            team9 = team9 + row.count;
          } else if (row.id.room === 10) {
            team10 = team10 + row.count;
          } else if (row.id.room === 11) {
            team11 = team11 + row.count;
          } else if (row.id.room === 12) {
            team12 = team11 + row.count;
          }
        }

        newLocal.push("aa");

        newLocal.push("1반" + "(" + team1 + ")");
        newLocal.push("2반" + "(" + team2 + ")");
        newLocal.push("3반" + "(" + team3 + ")");
        newLocal.push("4반" + "(" + team4 + ")");
        newLocal.push("5반" + "(" + team5 + ")");
        newLocal.push("6반" + "(" + team6 + ")");
        newLocal.push("7반" + "(" + team7 + ")");
        newLocal.push("8반" + "(" + team8 + ")");
        newLocal.push("9반" + "(" + team9 + ")");
        newLocal.push("10반" + "(" + team10 + ")");
        newLocal.push("11반" + "(" + team11 + ")");
        newLocal.push("12반" + "(" + team12 + ")");

        const teamSum =
          team1 +
          team2 +
          team3 +
          team4 +
          team5 +
          team6 +
          team7 +
          team8 +
          team9 +
          team10 +
          team11 +
          team12;
        newLocal.push("전체" + "(" + teamSum + ")");

        // moment.tz.setDefault("Asia/Seoul");
        // let now = moment();
        // let date = now.format("YYYY-MM-DD HH:mm:ss");

        //let todayOfWeek = now.format("e");
        // if (todayOfWeek == 2 || todayOfWeek == 4) {
        //   let start1 = moment(date).add(19, "hours");
        //   let start2 = moment(start1).add(30, "minutes");
        //   let end1 = moment(date).add(20, "hours");
        //   let end2 = moment(end1).add(30, "minutes");
        //   if (now >= start2 && now < end2) {
        //     isClassDay = true;
        //   } else {
        //     isClassDay = false;
        //   }
        // }
        // if (isClassDay === true) {
        //   alert(
        //     "화요일, 목요일 19시 30분부터 20시 30분까지는 이용이 불가합니다."
        //   );
        //   return;
        // }

        // let diffDay = moment("2022-09-22 20:00:00").diff(date, "hours") * -1;
        // let tempA = parseInt(diffDay / 168) + 2;
        // if (grade === "경전대학") {
        //   diffDay = moment("2022-09-27 20:00:00").diff(date, "hours") * -1;
        //   tempA = parseInt(diffDay / 168) + 1;
        // }

        // if (now < moment("2022-09-22 20:00:00") && grade === "불교대학") {
        //   tempA = 1;
        // } else if (
        //   now < moment("2022-10-04 20:00:00") &&
        //   grade === "경전대학"
        // ) {
        //   tempA = 1;
        // }
        let start = localStorage.getItem("start", ""); 
        let url2 = `manage/class2/lesson?today=today&grade=${grade}&year=${year}&semester=${semester}&start=${start}`;

        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d2 = data2.data;

          // if (query.value.lessonNo) {
          //   lessonNo = query.value.lessonNo;
          // } else {
          lessonNo = d2[0].lessonNo;
          // lessonNo = tempA;

          // if (lessonNo < 1) {
          //   lessonNo = 1;
          // }

          // if (lessonNo > 19) {
          //   lessonNo = 19;
          // }

          if (query.value.lessonName && query.value.lessonType === "online") {
            if (grade == "경전대학") {
              lessonNo = KLESSON_NAMES.indexOf(query.value.lessonName) + 1;
              lessonName.value = query.value.lessonName;
            } else if (grade === "불교대학") {
              lessonName.value = query.value.lessonName;
              lessonNo = BLESSON_NAMES.indexOf(query.value.lessonName) + 1;
            }
          }

          if (query.value.lessonNo) {
            lessonNo2.value = query.value.lessonNo;
          } else {
            lessonNo2.value = lessonNo;
          }

          tableData.value = [];

          if (query.value.lessonType === "online") {
            if (query.value.lessonNo) {
              lessonNo = Number(query.value.lessonNo);
              if (grade === "불교대학") {
                lessonName.value = BLESSON_NAMES[lessonNo - 1];
              } else if (grade === "경전대학") {
                lessonName.value = KLESSON_NAMES[lessonNo];
              }
            } else {
              if (grade === "불교대학") {
                lessonName.value = BLESSON_NAMES[lessonNo - 1];
              } else if (grade === "경전대학") {
                lessonName.value = KLESSON_NAMES[lessonNo];
              }
            }
          } else if (query.value.lessonType === "talk") {
            if (query.value.lessonNo) {
              lessonNo = Number(query.value.lessonNo);
              lessonName.value = "즉문즉설" + query.value.lessonNo;
            } else {
              lessonName.value = "즉문즉설1";
            }
          } else if (query.value.lessonType === "offline") {
            if (query.value.lessonNo) {
              lessonNo = Number(query.value.lessonNo);
              lessonName.value = "실천활동" + query.value.lessonNo;
            } else {
              lessonName.value = "실천활동1";
            }
          }

          if (query.value.lessonName && query.value.lessonType === "online") {
            if (grade == "경전대학") {
              lessonNo = KLESSON_NAMES.indexOf(query.value.lessonName) + 1;
              lessonName.value = query.value.lessonName;
            } else if (grade === "불교대학") {
              lessonName.value = query.value.lessonName;
              lessonNo = BLESSON_NAMES.indexOf(query.value.lessonName) + 1;
            }
          }

          if (grade === "경전대학") {
            const url = `manage/class2/attendStatsTotalLocal?local=${query.value.local}&grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
            api.get(url).then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data = response.data;
              const d = data.data;

              const aa1 = {};
              const aa2 = {};
              const aa3 = {};
              const aa4 = {};
              const aa5 = {};
              const aa6 = {};
              const aa7 = {};
              const aa8 = {};
              const aa9 = {};
              const aa10 = {};
              const aa11 = {};
              const aa12 = {};

              const a11 = [
                aa1,
                aa2,
                aa3,
                aa4,
                aa5,
                aa6,
                aa7,
                aa8,
                aa9,
                aa10,
                aa11,
                aa12,
              ];
              for (let i = 0; i < roomTotal; i++) {
                a11[i].no = i + 1;
                a11[i].local = LOCAL3[i + 1];
                a11[i].local2 = newLocal[i + 1];
                a11[i].attend = 0;
                a11[i].late = 0;
                a11[i].early = 0;
                a11[i].abscent = 0;
                a11[i].total = 0;
                a11[i].attend2 = 0;
                a11[i].late2 = 0;
                a11[i].early2 = 0;
                a11[i].abscent2 = 0;
                a11[i].total2 = 0;
              }

              let attendTotal = 0;
              let lateTotal = 0;
              let earlyTotal = 0;
              let abscentTotal = 0;
              let attendTotal2 = 0;
              let lateTotal2 = 0;
              let earlyTotal2 = 0;
              let abscentTotal2 = 0;

              if (data.rows > 0) {
                for (const row of d) {
                  for (let i = 0; i < roomTotal; i++) {
                    if (row.id.room === i + 1) {
                      if (row.id.belong === "주간") {
                        if (row.id.attend === 1) {
                          a11[i].attend = row.count;
                          attendTotal = attendTotal + row.count;
                        } else if (row.id.attend === -1) {
                          a11[i].late = row.count;
                          lateTotal = lateTotal + row.count;
                        } else if (row.id.attend === -2) {
                          a11[i].early = row.count;
                          earlyTotal = earlyTotal + row.count;
                        } else if (row.id.attend === 0) {
                          a11[i].abscent = row.count;
                          abscentTotal = abscentTotal + row.count;
                        }
                        a11[i].total =
                          a11[i].attend +
                          a11[i].late +
                          a11[i].early +
                          a11[i].abscent;
                        a11[i].attend3 =
                          a11[i].attend +
                          "(" +
                          ((a11[i].attend / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].late3 =
                          a11[i].late +
                          "(" +
                          ((a11[i].late / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].early3 =
                          a11[i].early +
                          "(" +
                          ((a11[i].early / a11[i].total) * 100).toFixed(1) +
                          ")";
                        a11[i].abscent3 =
                          a11[i].abscent +
                          "(" +
                          ((a11[i].abscent / a11[i].total) * 100).toFixed(1) +
                          ")";
                      } else {
                        if (row.id.attend === 1) {
                          a11[i].attend2 = row.count;
                          attendTotal2 = attendTotal2 + row.count;
                        } else if (row.id.attend === -1) {
                          a11[i].late2 = row.count;
                          lateTotal2 = lateTotal2 + row.count;
                        } else if (row.id.attend === -2) {
                          a11[i].early2 = row.count;
                          earlyTotal2 = earlyTotal2 + row.count;
                        } else if (row.id.attend === 0) {
                          a11[i].abscent2 = row.count;
                          abscentTotal2 = abscentTotal2 + row.count;
                        }
                        a11[i].total2 =
                          a11[i].attend2 +
                          a11[i].late2 +
                          a11[i].early2 +
                          a11[i].abscent2;
                        a11[i].attend4 =
                          a11[i].attend2 +
                          "(" +
                          ((a11[i].attend2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].late4 =
                          a11[i].late2 +
                          "(" +
                          ((a11[i].late2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].early4 =
                          a11[i].early2 +
                          "(" +
                          ((a11[i].early2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                        a11[i].abscent4 =
                          a11[i].abscent2 +
                          "(" +
                          ((a11[i].abscent2 / a11[i].total2) * 100).toFixed(1) +
                          ")";
                      }
                      a11[i].sumTotal = a11[i].total + a11[i].total2;
                      a11[i].sumAttend =
                        a11[i].attend +
                        a11[i].attend2 +
                        "(" +
                        (
                          ((a11[i].attend + a11[i].attend2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumLate =
                        a11[i].late +
                        a11[i].late2 +
                        "(" +
                        (
                          ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumEarly =
                        a11[i].early +
                        a11[i].early2 +
                        "(" +
                        (
                          ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumAbscent =
                        a11[i].abscent +
                        a11[i].abscent2 +
                        "(" +
                        (
                          ((a11[i].abscent + a11[i].abscent2) /
                            a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                    }
                  }
                }
              }

              a11[roomTotal].local2 = "전체" + "(" + teamSum + ")";
              a11[roomTotal].no = roomTotal + 1;
              a11[roomTotal].total =
                attendTotal + earlyTotal + lateTotal + abscentTotal;
              a11[roomTotal].total2 =
                attendTotal2 + earlyTotal2 + lateTotal2 + abscentTotal2;

              a11[roomTotal].attend3 =
                attendTotal +
                "(" +
                ((attendTotal / a11[roomTotal].total) * 100).toFixed(1) +
                ")";
              a11[roomTotal].late3 =
                lateTotal +
                "(" +
                ((lateTotal / a11[roomTotal].total) * 100).toFixed(1) +
                ")";
              a11[roomTotal].abscent3 =
                abscentTotal +
                "(" +
                ((abscentTotal / a11[roomTotal].total) * 100).toFixed(1) +
                ")";
              a11[roomTotal].early3 =
                earlyTotal +
                "(" +
                ((earlyTotal / a11[roomTotal].total) * 100).toFixed(1) +
                ")";

              a11[roomTotal].attend4 =
                attendTotal2 +
                "(" +
                ((attendTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                ")";
              a11[roomTotal].late4 =
                lateTotal2 +
                "(" +
                ((lateTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                ")";
              a11[roomTotal].abscent4 =
                abscentTotal2 +
                "(" +
                ((abscentTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                ")";
              a11[roomTotal].early4 =
                earlyTotal2 +
                "(" +
                ((earlyTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                ")";
              a11[roomTotal].sumTotal =
                a11[roomTotal].total + a11[roomTotal].total2;
              a11[roomTotal].sumAttend =
                attendTotal +
                attendTotal2 +
                "(" +
                (
                  ((attendTotal + attendTotal2) / a11[roomTotal].sumTotal) *
                  100
                ).toFixed(1) +
                ")";
              a11[roomTotal].sumLate =
                lateTotal +
                lateTotal2 +
                "(" +
                (
                  ((lateTotal + lateTotal2) / a11[roomTotal].sumTotal) *
                  100
                ).toFixed(1) +
                ")";
              a11[roomTotal].sumEarly =
                earlyTotal +
                earlyTotal2 +
                "(" +
                (
                  ((earlyTotal + earlyTotal2) / a11[roomTotal].sumTotal) *
                  100
                ).toFixed(1) +
                ")";
              a11[roomTotal].sumAbscent =
                abscentTotal +
                abscentTotal2 +
                "(" +
                (
                  ((abscentTotal + abscentTotal2) / a11[roomTotal].sumTotal) *
                  100
                ).toFixed(1) +
                ")";
              tableData.value = a11;
            });
          } else {
            if (jeon === "전국반") {
              const url = `manage/class2/attendStatsTotalLocal?local=${local}&grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
              api.get(url).then((response3) => {
                if (!api.isSuccess(response3)) {
                  return;
                }
                const data3 = response3.data;

                const d3 = data3.data;
                const aa1 = {};
                const aa2 = {};
                const aa3 = {};

                const a11 = [aa1, aa2, aa3];

                for (let i = 0; i < 3; i++) {
                  a11[i].no = i + 1;
                  a11[i].local2 = newLocal[i + 1];
                  a11[i].attend = 0;
                  a11[i].late = 0;
                  a11[i].early = 0;
                  a11[i].abscent = 0;
                  a11[i].total = 0;
                  a11[i].attend2 = 0;
                  a11[i].late2 = 0;
                  a11[i].early2 = 0;
                  a11[i].abscent2 = 0;
                  a11[i].total2 = 0;
                }

                let attendTotal = 0;
                let lateTotal = 0;
                let earlyTotal = 0;
                let abscentTotal = 0;
                let attendTotal2 = 0;
                let lateTotal2 = 0;
                let earlyTotal2 = 0;
                let abscentTotal2 = 0;

                if (data3.rows > 0) {
                  for (const row of d3) {
                    for (let i = 0; i < 2; i++) {
                      if (row.id.room === i + 8) {
                        if (row.id.belong === "주간") {
                          if (row.id.attend === 1) {
                            a11[i].attend = row.count;
                            attendTotal = attendTotal + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late = row.count;
                            lateTotal = lateTotal + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early = row.count;
                            earlyTotal = earlyTotal + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent = row.count;
                            abscentTotal = abscentTotal + row.count;
                          }
                          a11[i].total =
                            a11[i].attend +
                            a11[i].late +
                            a11[i].early +
                            a11[i].abscent;

                          a11[i].attend3 =
                            a11[i].attend +
                            "(" +
                            ((a11[i].attend / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].late3 =
                            a11[i].late +
                            "(" +
                            ((a11[i].late / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].early3 =
                            a11[i].early +
                            "(" +
                            ((a11[i].early / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent3 =
                            a11[i].abscent +
                            "(" +
                            ((a11[i].abscent / a11[i].total) * 100).toFixed(1) +
                            ")";
                        } else {
                          if (row.id.attend === 1) {
                            a11[i].attend2 = row.count;
                            attendTotal2 = attendTotal2 + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late2 = row.count;
                            lateTotal2 = lateTotal2 + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early2 = row.count;
                            earlyTotal2 = earlyTotal2 + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent2 = row.count;
                            abscentTotal2 = abscentTotal2 + row.count;
                          }
                          a11[i].total2 =
                            a11[i].attend2 +
                            a11[i].late2 +
                            a11[i].early2 +
                            a11[i].abscent2;
                          a11[i].attend4 =
                            a11[i].attend2 +
                            "(" +
                            ((a11[i].attend2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                          a11[i].late4 =
                            a11[i].late2 +
                            "(" +
                            ((a11[i].late2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].early4 =
                            a11[i].early2 +
                            "(" +
                            ((a11[i].early2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent4 =
                            a11[i].abscent2 +
                            "(" +
                            ((a11[i].abscent2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                        }
                        a11[i].sumTotal = a11[i].total + a11[i].total2;
                        a11[i].sumAttend =
                          a11[i].attend +
                          a11[i].attend2 +
                          "(" +
                          (
                            ((a11[i].attend + a11[i].attend2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumLate =
                          a11[i].late +
                          a11[i].late2 +
                          "(" +
                          (
                            ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumEarly =
                          a11[i].early +
                          a11[i].early2 +
                          "(" +
                          (
                            ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumAbscent =
                          a11[i].abscent +
                          a11[i].abscent2 +
                          "(" +
                          (
                            ((a11[i].abscent + a11[i].abscent2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                      }
                      a11[i].sumTotal = a11[i].total + a11[i].total2;
                      a11[i].sumAttend =
                        a11[i].attend +
                        a11[i].attend2 +
                        "(" +
                        (
                          ((a11[i].attend + a11[i].attend2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumLate =
                        a11[i].late +
                        a11[i].late2 +
                        "(" +
                        (
                          ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumEarly =
                        a11[i].early +
                        a11[i].early2 +
                        "(" +
                        (
                          ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                      a11[i].sumAbscent =
                        a11[i].abscent +
                        a11[i].abscent2 +
                        "(" +
                        (
                          ((a11[i].abscent + a11[i].abscent2) /
                            a11[i].sumTotal) *
                          100
                        ).toFixed(1) +
                        ")";
                    }
                  }
                }

                a11[2].local2 = "전체" + "(" + teamSum + ")";
                a11[2].no = roomTotal + 1;
                a11[2].total =
                  attendTotal + earlyTotal + lateTotal + abscentTotal;
                a11[2].total2 =
                  attendTotal2 + earlyTotal2 + lateTotal2 + abscentTotal2;

                a11[2].attend3 =
                  attendTotal +
                  "(" +
                  ((attendTotal / a11[2].total) * 100).toFixed(1) +
                  ")";
                a11[2].late3 =
                  lateTotal +
                  "(" +
                  ((lateTotal / a11[2].total) * 100).toFixed(1) +
                  ")";
                a11[2].abscent3 =
                  abscentTotal +
                  "(" +
                  ((abscentTotal / a11[2].total) * 100).toFixed(1) +
                  ")";
                a11[2].early3 =
                  earlyTotal +
                  "(" +
                  ((earlyTotal / a11[2].total) * 100).toFixed(1) +
                  ")";

                a11[2].attend4 =
                  attendTotal2 +
                  "(" +
                  ((attendTotal2 / a11[2].total2) * 100).toFixed(1) +
                  ")";
                a11[2].late4 =
                  lateTotal2 +
                  "(" +
                  ((lateTotal2 / a11[2].total2) * 100).toFixed(1) +
                  ")";
                a11[2].abscent4 =
                  abscentTotal2 +
                  "(" +
                  ((abscentTotal2 / a11[2].total2) * 100).toFixed(1) +
                  ")";
                a11[2].early4 =
                  earlyTotal2 +
                  "(" +
                  ((earlyTotal2 / a11[2].total2) * 100).toFixed(1) +
                  ")";

                tableData.value = a11;
              });
            } else {
              const url = `manage/class2/attendStatsTotalLocal?local=${query.value.local}&grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
              api.get(url).then((response) => {
                if (!api.isSuccess(response)) {
                  return;
                }
                const data = response.data;
                const d = data.data;
                const aa1 = {};
                const aa2 = {};
                const aa3 = {};
                const aa4 = {};
                const aa5 = {};
                const aa6 = {};
                const aa7 = {};
                const aa8 = {};
                const aa9 = {};
                const aa10 = {};
                const aa11 = {};
                const aa12 = {};

                const a11 = [
                  aa1,
                  aa2,
                  aa3,
                  aa4,
                  aa5,
                  aa6,
                  aa7,
                  aa8,
                  aa9,
                  aa10,
                  aa11,
                  aa12,
                ];
                if (query.value.local === "강원경기동부") {
                  roomTotal = 7;
                }
                if (jeon === "전국반") {
                  roomTotal = 2;
                }
                for (let i = 0; i < roomTotal; i++) {
                  a11[i].no = i + 1;
                  a11[i].local2 = newLocal[i + 1];
                  a11[i].attend = 0;
                  a11[i].late = 0;
                  a11[i].early = 0;
                  a11[i].abscent = 0;
                  a11[i].total = 0;
                  a11[i].attend2 = 0;
                  a11[i].late2 = 0;
                  a11[i].early2 = 0;
                  a11[i].abscent2 = 0;
                  a11[i].total2 = 0;
                }

                let attendTotal = 0;
                let lateTotal = 0;
                let earlyTotal = 0;
                let abscentTotal = 0;
                let attendTotal2 = 0;
                let lateTotal2 = 0;
                let earlyTotal2 = 0;
                let abscentTotal2 = 0;

                if (data.rows > 0) {
                  for (const row of d) {
                    for (let i = 0; i < roomTotal; i++) {
                      if (row.id.room === i + 1) {
                        if (row.id.belong === "주간") {
                          if (row.id.attend === 1) {
                            a11[i].attend = row.count;
                            attendTotal = attendTotal + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late = row.count;
                            lateTotal = lateTotal + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early = row.count;
                            earlyTotal = earlyTotal + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent = row.count;
                            abscentTotal = abscentTotal + row.count;
                          }
                          a11[i].total =
                            a11[i].attend +
                            a11[i].late +
                            a11[i].early +
                            a11[i].abscent;

                          a11[i].attend3 =
                            a11[i].attend +
                            "(" +
                            ((a11[i].attend / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].late3 =
                            a11[i].late +
                            "(" +
                            ((a11[i].late / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].early3 =
                            a11[i].early +
                            "(" +
                            ((a11[i].early / a11[i].total) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent3 =
                            a11[i].abscent +
                            "(" +
                            ((a11[i].abscent / a11[i].total) * 100).toFixed(1) +
                            ")";
                        } else {
                          if (row.id.attend === 1) {
                            a11[i].attend2 = row.count;
                            attendTotal2 = attendTotal2 + row.count;
                          } else if (row.id.attend === -1) {
                            a11[i].late2 = row.count;
                            lateTotal2 = lateTotal2 + row.count;
                          } else if (row.id.attend === -2) {
                            a11[i].early2 = row.count;
                            earlyTotal2 = earlyTotal2 + row.count;
                          } else if (row.id.attend === 0) {
                            a11[i].abscent2 = row.count;
                            abscentTotal2 = abscentTotal2 + row.count;
                          }
                          a11[i].total2 =
                            a11[i].attend2 +
                            a11[i].late2 +
                            a11[i].early2 +
                            a11[i].abscent2;
                          a11[i].attend4 =
                            a11[i].attend2 +
                            "(" +
                            ((a11[i].attend2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                          a11[i].late4 =
                            a11[i].late2 +
                            "(" +
                            ((a11[i].late2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].early4 =
                            a11[i].early2 +
                            "(" +
                            ((a11[i].early2 / a11[i].total2) * 100).toFixed(1) +
                            ")";
                          a11[i].abscent4 =
                            a11[i].abscent2 +
                            "(" +
                            ((a11[i].abscent2 / a11[i].total2) * 100).toFixed(
                              1
                            ) +
                            ")";
                        }
                        a11[i].sumTotal = a11[i].total + a11[i].total2;
                        a11[i].sumAttend =
                          a11[i].attend +
                          a11[i].attend2 +
                          "(" +
                          (
                            ((a11[i].attend + a11[i].attend2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumLate =
                          a11[i].late +
                          a11[i].late2 +
                          "(" +
                          (
                            ((a11[i].late + a11[i].late2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumEarly =
                          a11[i].early +
                          a11[i].early2 +
                          "(" +
                          (
                            ((a11[i].early + a11[i].early2) / a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                        a11[i].sumAbscent =
                          a11[i].abscent +
                          a11[i].abscent2 +
                          "(" +
                          (
                            ((a11[i].abscent + a11[i].abscent2) /
                              a11[i].sumTotal) *
                            100
                          ).toFixed(1) +
                          ")";
                      }
                    }
                  }
                }

                a11[roomTotal].local2 = "전체" + "(" + teamSum + ")";
                a11[roomTotal].no = roomTotal + 1;
                a11[roomTotal].total =
                  attendTotal + earlyTotal + lateTotal + abscentTotal;
                a11[roomTotal].total2 =
                  attendTotal2 + earlyTotal2 + lateTotal2 + abscentTotal2;

                a11[roomTotal].attend3 =
                  attendTotal +
                  "(" +
                  ((attendTotal / a11[roomTotal].total) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].late3 =
                  lateTotal +
                  "(" +
                  ((lateTotal / a11[roomTotal].total) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].abscent3 =
                  abscentTotal +
                  "(" +
                  ((abscentTotal / a11[roomTotal].total) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].early3 =
                  earlyTotal +
                  "(" +
                  ((earlyTotal / a11[roomTotal].total) * 100).toFixed(1) +
                  ")";

                a11[roomTotal].attend4 =
                  attendTotal2 +
                  "(" +
                  ((attendTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].late4 =
                  lateTotal2 +
                  "(" +
                  ((lateTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].abscent4 =
                  abscentTotal2 +
                  "(" +
                  ((abscentTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].early4 =
                  earlyTotal2 +
                  "(" +
                  ((earlyTotal2 / a11[roomTotal].total2) * 100).toFixed(1) +
                  ")";
                a11[roomTotal].sumTotal =
                  a11[roomTotal].total + a11[roomTotal].total2;
                a11[roomTotal].sumAttend =
                  attendTotal +
                  attendTotal2 +
                  "(" +
                  (
                    ((attendTotal + attendTotal2) / a11[roomTotal].sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                a11[roomTotal].sumLate =
                  lateTotal +
                  lateTotal2 +
                  "(" +
                  (
                    ((lateTotal + lateTotal2) / a11[roomTotal].sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                a11[roomTotal].sumEarly =
                  earlyTotal +
                  earlyTotal2 +
                  "(" +
                  (
                    ((earlyTotal + earlyTotal2) / a11[roomTotal].sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                a11[roomTotal].sumAbscent =
                  abscentTotal +
                  abscentTotal2 +
                  "(" +
                  (
                    ((abscentTotal + abscentTotal2) / a11[roomTotal].sumTotal) *
                    100
                  ).toFixed(1) +
                  ")";
                tableData.value = a11;
              });
            }
          }
        });
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      verboseSearchOptionManager.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor");
      verboseSearchOptionBranch.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("mentor") ||
        token.role.includes("branch");
      verboseSearchOptionClassLeader.value =
        token.role.includes("manager") ||
        token.role.includes("master") ||
        token.role.includes("mentor") ||
        token.role.includes("class leader") ||
        token.role.includes("branch") ||
        token.role.includes("observer");
      verboseSearchOptionMaster.value =
        token.role.includes("master") || token.role.includes("manager");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);
    const search = () => {
      if (query.value.local) {
        if (query.value.local === "전체") {
          getDataFromApi();
          query.value.local = "";
        } else {
          getData();
        }
      } else {
        getDataFromApi();
      }
      if (query.value.lessonType == "talk") {
        bsection.value = "talk";
      } else if (query.value.lessonType == "offline") {
        bsection.value = "offline";
      } else if (query.value.lessonType == "online") {
        if (grade == "경전대학") {
          bsection.value = "경전대학";
        } else if (grade == "불교대학") {
          bsection.value = "불교대학";
        }
      }
    };

    const closedModal = () => {
      modalActive.value = false;
    };
    const excelSave = () => {
      const path = `manage/class2/attendDownload?grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
      api.download(path);
    };
    const googleSave = () => {
      const path = `manage/class2/attendDownload3?grade=${grade}&lessonType=${query.value.lessonType}&year=${year}&semester=${semester}&lessonNo=${lessonNo}&lessonName=${query.value.lessonName}`;
      api.download(path);
      alert("구글로 보냈습니다.");
      if (grade === "불교대학") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1iukTSh18DQ_UH0esQwoCHIxmbpcyUxog3loHWrleg5w/edit?usp=share_link"
        );
      } else if (grade === "경전대학") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1-Y7gZg6Ff6bMYgrAEzxhV4M62XS4GoGoO975TMtFNwA/edit?usp=share_link"
        );
      }
    };
    return {
      header,
      page2,
      ROOM_NUMBER,
      TEAM_NUMBER,
      SEMESTER2,
      LOCAL4,
      lessonName,
      lessonNo2,
      LESSON_TYPES,
      BLESSON_NAMES,
      KLESSON_NAMES,
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      gradeChange,
      lessonTypeChange,
      bsection,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      verboseSearchOptionManager,
      verboseSearchOptionSemester,
      verboseSearchOptionBranch,
      verboseSearchOptionMaster,
      verboseSearchOptionClassLeader,
      modalActive,
      closedModal,
      excelSave,
      googleSave,
      getData,
      search,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
