<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar
          is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        /> -->
      </div>
    </div>
  </div>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100 bg-gradient-primary">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h4 class="text-primary">{{ TITLE }}</h4>
                <!-- <p class="text-lead text-danger text-center">
                  서버 작업중 잠시만 기다려주세요!!
                </p> -->
                <p class="text-lead text-primary text-center">환영합니다!!</p>
                <p class="text-lead text-primary text-center">
                  정토 플랫폼을 이용하시려면
                </p>
                <p class="text-lead text-primary text-center">
                  아래 버튼을 눌러 로그인해주세요!
                </p>
                <div class="btn-wrapper text-center">
                  <a
                    href="#"
                    class="btn btn-neutral btn-icon btn-sm mb-0 mt-3"
                    @click.prevent="login"
                  >
                    <img
                      class="w-30"
                      src="../../../assets/img/logos/google.svg"
                    />
                    Google 로그인
                  </a>
                </div>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4"></div>
                <form role="form" class="text-start">
                  <div class="mb-2 position-relative text-center"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
// import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
import { mapMutations } from "vuex";
import { TITLE } from "@/consts/names";
import { googleOneTap, googleTokenLogin } from "vue3-google-login";
import axios from "axios";

export default {
  name: "SigninBasic",
  components: {
    // Navbar,
    AppFooter,
    // ArgonInput,
    // ArgonSwitch,
    // ArgonButton,
  },
  data() {
    return {
      TITLE,
    };
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    localStorage.clear();
    sessionStorage.clear();
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    ...mapMutations(["toggleDefaultLayout"]),
    login() {
      googleOneTap()
        .then((res) => {
          // This promise is resolved when user selects an account from the the One Tap prompt
          const axiosOptions = {
            headers: { "Content-Type": "application/json" },
          };
          const API_URL = process.env.VUE_APP_API_BASE_URL;
          axios
            .post(`${API_URL}/base/auth2`, res, axiosOptions)
            .then((res) => {
              const payload = res.data.data;

              if (payload) {
                sessionStorage.setItem("accessToken", payload.accessToken);
                sessionStorage.setItem("refreshToken", payload.refreshToken);
                sessionStorage.setItem("email", payload.email);
                sessionStorage.setItem("name", payload.name);
                sessionStorage.setItem("nickname", payload.nickname);
                sessionStorage.setItem("role", payload.role);

                if (payload?.picture)
                  sessionStorage.setItem("picture", payload.picture);

                if (sessionStorage.getItem("fullPath"))
                  document.location.replace(
                    "/#" + sessionStorage.getItem("fullPath")
                  );
                else document.location.replace("/");

                sessionStorage.removeItem("fullPath");
              } else {
                alert("login failure");
                document.location.replace("/#/login");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("Handle the error", error);
          googleTokenLogin().then((response) => {
            console.log("Handle the response", response);
            const axiosOptions = {
              headers: { "Content-Type": "application/json" },
            };
            const API_URL = process.env.VUE_APP_API_BASE_URL;
            axios
              .post(`${API_URL}/base/auth2`, response, axiosOptions)
              .then((res) => {
                const payload = res.data.data;

                if (payload) {
                  sessionStorage.setItem("accessToken", payload.accessToken);
                  sessionStorage.setItem("refreshToken", payload.refreshToken);
                  sessionStorage.setItem("email", payload.email);
                  sessionStorage.setItem("name", payload.name);
                  sessionStorage.setItem("nickname", payload.nickname);
                  sessionStorage.setItem("role", payload.role);

                  if (payload?.picture)
                    sessionStorage.setItem("picture", payload.picture);

                  if (sessionStorage.getItem("fullPath"))
                    document.location.replace(
                      "/#" + sessionStorage.getItem("fullPath")
                    );
                  else document.location.replace("/");

                  sessionStorage.removeItem("fullPath");
                } else {
                  alert("login failure");
                  document.location.replace("/#/login");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          });
        });
    },
  },
};
</script>
