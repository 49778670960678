<template>
  <modal v-model:show="modal" size="lg" body-classes="p-0">
    <template #header>
      <h6 class="modal-title">댓글 {{ modalModeText }}</h6>
    </template>
    <card type="secondary" body-classes="px-lg-5">
      <form role="form">
        <div class="row">
          <label class="form-control-label">댓글</label>
        </div>
        <QuillEditor
          id="editor"
          ref="quillEditor"
          v-model:content="newData.comment"
          content-type="html"
          theme="snow"
          toolbar="full"
          :modules="modules"
        />
        <div class="text-center">
          <base-button type="primary" class="my-4" @click="onSave"
            >{{ modalModeText }} 하기</base-button
          >
          <base-button type="secondary" class="my-4" @click="modal = false"
            >닫 기</base-button
          >
        </div>
      </form>
    </card>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import EditModalMixin, { ADD } from "@/views/Template/EditModalMixin";
import * as api from "@/api";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

export default {
  components: {
    Modal,
    QuillEditor,
  },
  mixins: [EditModalMixin],
  props: {
    noticeId: {
      type: String,
      required: true,
    },
  },
  emits: ["addDone", "editDone"],
  data() {
    return {
      someText: "",
      newData: {
        id: 0,
      },
      darkMode: false,
      modules: {
        name: "imageUploader",
        module: ImageUploader,
        options: {
          upload: (file) => {
            return new Promise((resolve, reject) => {
              const formData = new FormData();
              formData.append("filelist", file);
              axios
                .post(`${process.env.VUE_APP_API_BASE_URL}/imagesave`, formData)
                .then((res) => {
                  console.log(res);
                  resolve(res.data.url);
                })
                .catch((err) => {
                  reject("Upload failed");
                  console.error("Error:", err);
                });
            });
          },
        },
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.quillEditor;
    },
    modalModeText: function () {
      return this.modalMode == ADD ? "등록" : "수정";
    },
  },
  methods: {
    getSetText() {
      this.someText = "<div><p>this is some text</p> </div>";
      this.editor.setHTML(this.someText);
    },
    // 등록하기
    onAdd() {
      const url = `manage/class2/proposal/${this.noticeId}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        let row = response.data.data;
        const data = {};
        if (row.commentHit) {
          data.commentHit = row.commentHit + 1;
        } else {
          data.commentHit = 1;
        }
        api.put(`manage/class2/proposal/${row.id}`, { data }).then(() => {});
      });
      const data = JSON.parse(JSON.stringify(this.newData));
      data.noticeId = this.noticeId;
      api.post("manage/class2/comment", { data }).then(() => {
        this.$emit("addDone", data);
        this.hideModal();
      });
    },
    // 수정하기
    onEdit(row) {
      const data = JSON.parse(JSON.stringify(this.newData));
      api.put(`manage/class2/comment/${row.id}`, { data }).then(() => {
        this.$emit("editDone", row, data);
        this.hideModal();
      });
    },
    // 등록창 열기
    handleAdd() {
      let modal = document.querySelector(".modal-content");
      let modal2 = document.querySelector(".modal-inner");
      if (this.$store.state.darkMode == true) {
        modal.style.backgroundColor = "#172b4d";
        modal2.style.backgroundColor = "#172b4d";
      } else {
        modal.style.backgroundColor = "#ffffff";
        modal2.style.backgroundColor = "#ffffff";
      }
      this.newData.comment = "";
      this.getSetText();
      this.showAddModal();
    },
    // 수정창 열기
    handleEdit(row) {
      this.loadModal(`manage/class2/comment/${row.id}`, row, (data) => {
        this.newData = data;
        return {
          id: data.id,
          comment: data.comment,
        };
      });
    },
  },
};
</script>
