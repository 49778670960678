<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">비정규수업</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                    @change="getDataFromApi(1)"
                  />
                </div>
                  
                <div v-if="inputPossibleManager" class="col text-end mt-4">
                  <base-button type="primary" class="btn-sm" @click="handleAdd"
                    >+추가</base-button
                  >
                </div>
                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>
            <Modal :modal-active="modalActive" @close="closedModal">
              <div class="modal-content">
                <h1>수업</h1>
                <form role="form">
                  <div class="row-sm-3 mb-2">
                    <preset-radio
                      v-if="inputPossibleManager"
                      v-model="newData.grade"
                      :choices="[
                        ['불교대학', '불교대학'],
                        ['경전대학', '경전대학'],
                      ]"
                      @click="onBuldae"
                    />
                    <preset-radio
                      v-else
                      v-model="newData.grade" disabled
                      :choices="[
                        ['불교대학', '불교대학'],
                        ['경전대학', '경전대학'],
                      ]"
                      @click="onBuldae"
                    />
                  </div>
                  <div class="row ps-1">    
                    <base-input v-model="newData.lessonName" type="text" >
                      <template #addonLeft> 강의명</template>
                    </base-input>
                  </div>
                  <base-input 
                    v-model="newData.lessonDate2"
                    type="datetime-local"
                  >
                    <template #addonLeft> 법문강의일</template>
                  </base-input>
                  
                  <base-input v-model="newData.start2" type="datetime-local">
                    <template #addonLeft> 미트 입장 시작</template>
                  </base-input>
                  <base-input v-model="newData.end2" type="datetime-local">
                    <template #addonLeft> 미트 입장 종료</template>
                  </base-input>   
                   
                  <div class="text-center">
                    <base-button
                      v-if="add"
                      type="primary"
                      class="mb-2"
                      @click="onAdd"
                      >등록 하기</base-button
                    >
                    <base-button
                      v-else
                      type="primary"
                      class="mb-2"
                      @click="onEdit(newData)"
                      >수정 하기</base-button
                    >
                    <base-button
                      type="secondary"
                      class="mb-2 ms-2"
                      @click="closedModal"
                      >닫 기</base-button
                    >
                  </div>
                </form>
              </div>
            </Modal>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td> 
                    <td>{{ row.lessonName }}</td> 
                    <td>{{ row.lessonDate }}</td> 
                    <td>{{ row.start }}</td> 
                    <td>{{ row.end }}</td> 

                    <td width="10%">
                      <div v-if="isAction" class="d-flex pe-4">
                        <argon-button
                          v-if="inputPossibleManager"
                          class="edit me-1"
                          color="warning"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleEdit(row)"
                        >
                          수정
                        </argon-button>
                        <argon-button
                          v-if="inputPossibleManager"
                          class="remove btn-link"
                          color="danger"
                          size="sm"
                          style="padding: 5px 10px 5px 10px"
                          @click.stop="handleDelete(row)"
                        >
                          삭제
                        </argon-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import PresetRadio from "@/components/Inputs/PresetRadio";
import Modal from "./Board/Modal";
import moment from "moment";
import { SEMESTER } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
    PresetRadio,
    Modal,
  },
  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년", 
      "수업명",
      "수업일",
      "미트 시작",
      "미트 종료",
      "Action",
    ]);
    const query = ref({
      semester: "", 
      bound: 20,
    });
    let inputPossibleManager = ref(false);
    let inputPossibleMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    const newData = ref({});
    let modalActive = ref(false);
    const add = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    const isBuldae = ref(false);
    modalActive.value = false; 
    let isAction = ref(false);

    // const token = api.Auth.tokenContent;
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    // query.value.bound = token.listType;
    const token = api.Auth.tokenContent;
    query.value.bound = token.listType;
    perPage.value = query.value.bound;

    if (token.grade == "불교대학") {
      isBuldae.value = true;
    } else if (token.grade == "경전대학") {
      isBuldae.value = false;
    }
    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }

      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }
      if (year.value == token.year && semester.value == token.semester) {
        isAction.value = true;
      } else {
        isAction.value = false;
      }
      // if (query.value.semester == "불교대학 2022 9월") {
      //   grade.value = "불교대학";
      // } else if (query.value.semester == "경전대학 2022 9월") {
      //   grade.value = "경전대학";
      // }

      

      const url = `manage/class2/lessonIrregular?bound=${query.value.bound}
   &page=${page}&grade=${grade.value}&year=${year.value}&semester=${semester.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data; 

        if (data.rows > 0) {
          for (const row of d) {
              row.start = moment(row.start).format("YYYY-MM-DD HH:mm");
              row.end = moment(row.end).format("YYYY-MM-DD HH:mm");
            if (row.lessonDate) {
              row.lessonDate = moment(row.lessonDate).format("YYYY-MM-DD HH:mm");
            }
          }
          tableData.value = d;
          total.value = data.total;
        }
        
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      inputPossibleManager.value =
        token.role.includes("manager") || token.role.includes("master");
      inputPossibleMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);
    const goMeet = (url) => {
      window.open(url);
    };

    const closedModal = () => {
      modalActive.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.grade = "";
      add.value = true;
      newData.value.lessonDate = "";
      newData.value.lessonDate2 = "";
      newData.value.start2 = "";
      newData.value.end2 = "";
      newData.value.lessonName = "";  
      newData.value.grade = token.grade;  
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/lessonIrregular/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        const data = response.data.data;
        
        if (data.grade == "불교대학") {
          isBuldae.value = true;
        } else if (data.grade == "경전대학") {
          isBuldae.value = false;
        }
        newData.value = data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
     
      api.post("manage/class2/lessonIrregular", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
     
      api.put(`manage/class2/lessonIrregular/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/lessonIrregular/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    const onBuldae = () => {
      if (newData.value.grade == "불교대학") {
        isBuldae.value = false;
      } else if (newData.value.grade == "경전대학") {
        isBuldae.value = true;
      }
    };
    return {
      header,
      page2,
      SEMESTER, 
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      inputPossibleManager,
      inputPossibleMaster,
      verboseSearchOptionSemester,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
      isAction,
      isBuldae,
      onBuldae,
    };
  },
  mounted() {
    const modal = document.querySelectorAll(".modal-inner");
    const modal2 = document.querySelectorAll(".modal-content");
    if (this.$store.state.darkMode) {
      for (var i = 0; i < modal.length; i++) {
        modal[i].style.backgroundColor = "#172b4d";
        modal2[i].style.backgroundColor = "#172b4d";
      }
    }
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
