<!-- 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">게시판</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="col text-end mt-2 me-2">
              <!-- New Button -->
              <base-button type="primary" class="btn-sm" @click="goList"
                >목록으로 가기</base-button
              >
              <!-- End New Button -->
            </div>
            <div class="card-body">
              <table align="center" width="80%">
                <td class="list-group-item px-2">
                  <h4 class="text-left">제목</h4>
                  <p v-dompurify-html="title" class="text-left"></p>
                  <hr />
                  <h4 class="text-left">내용</h4>
                  <p v-dompurify-html="content" class="text-left"></p>
                </td>
                <br />
              </table>
            </div>
          </div>
          <div v-if="isComment" class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">댓글</h3>
                </div>
              </div>
            </div>

            <div class="card-body text-center">
              <!-- Search -->
              <form class="form-inline mb-2">
                <div class="row">
                  <div class="col-sm-2">
                    <input
                      v-model="query.name"
                      type="text"
                      class="form-control col"
                      placeholder="회원이름"
                      @keyup.enter="getDataFromApi(1)"
                    />
                  </div>
                  <div class="col-sm-2">
                    <input
                      v-model="query.comment"
                      type="text"
                      class="form-control col"
                      placeholder="댓글"
                      @keyup.enter="getDataFromApi(1)"
                    />
                  </div>

                  <div class="col-sm-2">
                    <base-button
                      class="btn-sm"
                      type="secondary"
                      @click.prevent="getDataFromApi(1)"
                      >검색</base-button
                    >
                  </div>
                  <div class="col-sm-2">
                    <base-button
                      class="btn-sm"
                      type="secondary"
                      @click.prevent="reset()"
                      >초기화</base-button
                    >
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col text-left"></div>
                <div class="col text-end">
                  <!-- New Button -->

                  <base-button
                    type="primary"
                    class="btn-sm"
                    @click="$refs.editModal.handleAdd()"
                    >+댓글추가</base-button
                  >
                  <!-- End New Button -->
                </div>
              </div>
              <!-- modal -->
              <edit-modal
                ref="editModal"
                :notice-id="noticeId"
                @add-done="onAddDone"
                @edit-done="onEditDone"
              />
              <modal :modal-active="modalActive" @close="closedModal">
                <div id="modal-content">
                  <h6 class="modal-title">대댓글 {{ modalModeText }}</h6>
                  <card type="secondary" body-classes="px-lg-5">
                    <form role="form">
                      <div class="row">
                        <label class="form-control-label">대댓글</label>
                      </div>
                      <QuillEditor
                        id="editor"
                        v-model:content="comment"
                        content-type="html"
                        theme="snow"
                        toolbar="full"
                        :modules="modules"
                      />
                      <!-- <div :key="htmlEditorKey">
                        <html-editor v-model="comment" />
                      </div> -->
                      <div class="text-center">
                        <base-button
                          v-if="modalModeText == '등록'"
                          type="primary"
                          class="my-4"
                          @click="onAdd()"
                          >{{ modalModeText }} 하기</base-button
                        >
                        <base-button
                          v-if="modalModeText == '수정'"
                          type="primary"
                          class="my-4"
                          @click="onEdit()"
                          >{{ modalModeText }} 하기</base-button
                        >
                        <base-button
                          type="secondary"
                          class="my-4"
                          @click="modalActive = false"
                          >닫 기</base-button
                        >
                      </div>
                    </form>
                  </card>
                </div>
              </modal>
              <!-- End Search -->
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead>
                    <th width="5%" min-width="50px">번호</th>
                    <th width="20%" min-width="150px">소속</th>
                    <th width="10%" min-width="100px">이름</th>
                    <th width="40%" min-width="250px">댓글</th>
                    <th width="10%" min-width="150px">작성일</th>
                    <th width="20%" min-width="200px">Action</th>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div class="card-body mx-0 px-0">
                <table align="center" width="100%">
                  <ul class="list-group list-group-flush list my--3">
                    <li
                      v-for="row in tableData"
                      :key="row.id"
                      class="list-group px-0"
                    >
                      <tr class="list-group-item">
                        <td width="5%" min-width="30px">{{ row.no }}</td>
                        <td width="20%" min-width="150px">{{ row.dept }}</td>
                        <td width="10%" min-width="100px">{{ row.name }}</td>
                        <td width="40%" min-width="250px">
                          <div
                            v-dompurify-html="row.comment"
                            style="word-break: break-all; text-align: left"
                          ></div>
                        </td>
                        <td width="10%" min-width="150px">
                          {{ row.createdTime }}
                        </td>
                        <td width="13%" min-width="200px">
                          <div class="d-flex">
                            <base-button
                              class="edit ms-2"
                              type="primary"
                              size="sm"
                              @click.stop="doubleCommentAdd(row)"
                            >
                              대댓글
                            </base-button>
                            <base-button
                              v-if="row.isEdit"
                              size="sm"
                              type="primary"
                              @click.stop="$refs.editModal.handleEdit(row)"
                            >
                              수정
                            </base-button>
                            <base-button
                              v-if="row.isDel"
                              size="sm"
                              type="warning"
                              @click.stop="handleDelete(row)"
                            >
                              삭제
                            </base-button>
                          </div>
                        </td>
                      </tr>

                      <ul class="list-group list-group-flush list my--2">
                        <li
                          v-for="(row2, index) in row.doubleComment"
                          :key="index"
                          class="list-group px-0"
                        >
                          <tr class="list-group-item">
                            <td width="1%"></td>
                            <td width="5%" min-width="50px">{{ row2.no2 }}</td>
                            <td width="20%" min-width="150px">
                              {{ row2.dept }}
                            </td>
                            <td width="10%" min-width="100px">
                              {{ row2.name }}
                            </td>
                            <td width="40%" min-width="250px">
                              <div
                                v-dompurify-html="row2.content"
                                style="word-break: break-all; text-align: left"
                              ></div>
                            </td>
                            <td width="10%" min-width="50px">
                              {{ row2.createdTime }}
                            </td>
                            <td width="10%" min-width="50px">
                              <div class="d-flex">
                                <base-button
                                  v-if="row2.isEdit"
                                  class="me-2"
                                  size="sm"
                                  type="primary"
                                  @click.stop="
                                    doubleCommentEdit(row2, row, index)
                                  "
                                >
                                  수정
                                </base-button>
                                <base-button
                                  size="sm"
                                  class="me-2"
                                  type="warning"
                                  @click.stop="handleDelete2(row, index)"
                                >
                                  삭제
                                </base-button>
                              </div>
                            </td>
                          </tr>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </table>
              </div>
              <!-- End List -->

              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  :rows-per-page="perPage"
                  :total="total"
                  @page-change="getDataFromApi"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import { ref } from "vue";
import Modal from "./Board/Modal";
import { useRouter } from "vue-router";
import EditModal from "./Board/EditModal2.vue";
import { useStore } from "vuex";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import ImageUploader from "quill-image-uploader";
import axios from "axios";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
    EditModal,
    QuillEditor,
    Modal,
  },
  setup() {
    const tableData = ref([]); // 레코드셋
    const tableData2 = ref([]); // 레코드셋
    const tableData3 = ref([]); // 레코드셋
    const perPage = ref(10); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    const start1 = ref(0);
    const start2 = ref(0);
    const end1 = ref(0);
    const modalModeText = ref("");
    let htmlEditorKey = ref(0);
    const end2 = ref(0);
    const content = ref("");
    const id = ref("");
    const title = ref("");
    const no = ref(0);
    const commentHit = ref(0);
    const commentId = ref("");
    let darkMode = ref(false);
    const verboseSearchOptionManager = ref(false);
    const inputPossibleBranch = ref(false);
    const inputPossibleClassLeader = ref(false);
    const noticeId = ref("");
    const isComment = ref(false);
    const comment = ref("");
    let modalActive = ref(false);
    const query = ref({
      // 검색 쿼리
      local: "",
      district: "",
      modum: "",
      name: "",
      comment: "",
    });
    const modules = {
      name: "imageUploader",
      module: ImageUploader,
      options: {
        upload: (file) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("filelist", file);
            axios
              .post(`${process.env.VUE_APP_API_BASE_URL}/imagesave`, formData)
              .then((res) => {
                console.log(res);
                resolve(res.data.url);
              })
              .catch((err) => {
                reject("Upload failed");
                console.error("Error:", err);
              });
          });
        },
      },
    };

    const reset = () => {
      query.value.local = "";
      query.value.name = "";
      query.value.comment = "";
      getDataFromApi(1);
    };
    const getContent = (content) => {
      return content.split("\n").join("<br>");
    };
    // api 호출
    const getUrlParams = () => {
      var params = {};

      window.location.search.replace(
        /[?&]+([^=&]+)=([^&]*)/gi,
        function (str, key, value) {
          params[key] = value;
        }
      );

      return params;
    };
    const getDataFromApi = (page = 1) => {
      const store = useStore();
      darkMode.value = store.state.darkMode;

      const suid = getUrlParams();
      noticeId.value = suid.suid;
      const url = `manage/class2/proposal/${noticeId.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const d = response.data.data;
        isComment.value = d.isComment;
        if (d.content) {
          d.content = d.content.replace("<img", '<img width="640"');
        }
        if (d.content.includes("\n")) {
          content.value = getContent(d.content);
          content.value = content.value.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        } else {
          content.value = d.content.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        }

        title.value = d.title;
        commentHit.value = d.commentHit;
        id.value = d.id;

        const url2 = `manage/class2/comment?page=${page}&noticeId=${noticeId.value}&name=${query.value.name}&comment=${query.value.comment}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d2 = data2.data;
          const token = api.Auth.tokenContent;
          if (data2.rows > 0) {
            moment.locale("ko");
            for (const row of d2) {
              row.isDel = true;
              row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
              row.dept = row.local + "/" + row.room + "/" + row.team;

              if (token.memberNo == row.memberNo) {
                row.isEdit = true;
              } else {
                row.isEdit = false;
              }
              if (row.comment) {
                if (row.comment.includes("\n")) {
                  row.comment = this.getContent(row.comment);
                }
              }

              let i = 1;
              // if (row.doubleComment.length > 0) {
              for (const item of row.doubleComment) {
                item.content = getContent(item.content);
                item.createdTime = moment(item.createdTime).format(
                  "YYYY-MM-DD"
                );
                item.no2 = "⤷" + i++;
                if (token.memberNo == item.memberNo) {
                  item.isEdit = true;
                } else {
                  item.isEdit = false;
                }
              }
              // }
            }
          }
          tableData.value = d2;
          total.value = data2.total;
        });
      });
    };
    getDataFromApi(1);
    const getDataFromApi2 = (page = 1) => {
      const suid = getUrlParams();
      noticeId.value = suid.suid;
      const url = `manage/class2/proposal/${noticeId.value}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const d = response.data.data;
        isComment.value = d.isComment;
        if (d.content.includes("\n")) {
          content.value = getContent(d.content);
          content.value = content.value.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        } else {
          content.value = d.content.replace(
            /class="ql-align-center"/gi,
            'align="center"'
          );
        }
        title.value = d.title;
        commentHit.value = d.commentHit;
        id.value = d.id;

        const url2 = `manage/class2/comment?page=${page}&noticeId=${noticeId.value}&name=${query.value.name}&comment=${query.value.comment}`;
        api.get(url2).then((response) => {
          if (!api.isSuccess(response)) {
            return;
          }
          const data2 = response.data;
          const d2 = data2.data;
          const token = api.Auth.tokenContent;
          if (data2.rows > 0) {
            moment.locale("ko");
            for (const row of d2) {
              row.isDel = true;
              row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
              row.dept = row.local + "/" + row.room + "/" + row.team;

              if (token.memberNo == row.memberNo) {
                row.isEdit = true;
              } else {
                row.isEdit = false;
              }
              if (row.comment) {
                if (row.comment.includes("\n")) {
                  row.comment = this.getContent(row.comment);
                }
              }

              let i = 1;
              // if (row.doubleComment.length > 0) {
              for (const item of row.doubleComment) {
                item.content = getContent(item.content);
                item.createdTime = moment(item.createdTime).format(
                  "YYYY-MM-DD"
                );
                item.no2 = "⤷" + i++;
                if (token.memberNo == item.memberNo) {
                  item.isEdit = true;
                } else {
                  item.isEdit = false;
                }
              }
              // }
            }
          }
          tableData.value = d2;
          total.value = data2.total;
        });
      });
    };
    const doubleCommentAdd = (row) => {
      modalActive.value = true;
      // let modal = document.querySelector("#modal-content");
      // let modal2 = document.querySelector(".modal-inner");
      // if( darkMode.value == true) {
      //   modal.style.backgroundColor = "#172b4d";
      //   modal2.style.backgroundColor = "#172b4d";
      // } else {
      //   modal.style.backgroundColor = "#ffffff";
      //   modal2.style.backgroundColor = "#ffffff";
      // }
      htmlEditorKey.value++;
      commentId.value = row.id;
      modalModeText.value = "등록";
      comment.value = "";
      tableData2.value = row.doubleComment;
    };
    const doubleCommentEdit = (row, row2, index) => {
      modalActive.value = true;
      htmlEditorKey.value++;

      no.value = index;
      commentId.value = row2.id;
      comment.value = row.content;
      tableData3.value = [];
      tableData3.value = row2;
      modalModeText.value = "수정";
    };

    const onAdd = () => {
      const token = api.Auth.tokenContent;
      const data = {};
      const aa = {};
      aa.name = token.name;
      aa.dept = token.local + "/" + token.room + "/" + token.team;
      aa.memberNo = token.memberNo;
      aa.content = comment.value;
      aa.createdTime = moment().format("YYYY-MM-DD hh-mm");
      aa.no = tableData2.value.length + 1;
      tableData3.value = [];
      tableData3.value = tableData2.value;
      tableData3.value.unshift(aa);
      data.doubleComment = tableData2.value;
      data.type = "doubleComment";
      api.put(`manage/class2/comment/${commentId.value}`, { data }).then(() => {
        getDataFromApi2(1);
        modalActive.value = false;
      });
    };
    // 수정하기
    const onEdit = () => {
      const token = api.Auth.tokenContent;
      const data = {};
      const aa = {};
      aa.name = token.name;
      aa.dept = token.local + "/" + token.room + "/" + token.team;
      aa.memberNo = token.memberNo;
      aa.content = comment.value;
      aa.no = no.value + 1;
      aa.createdTime = moment().format("YYYY-MM-DD hh-mm");
      tableData3.value.doubleComment[no.value] = aa;
      data.doubleComment = tableData3.value.doubleComment;
      data.type = "doubleComment";
      api.put(`manage/class2/comment/${commentId.value}`, { data }).then(() => {
        getDataFromApi2(1);
        modalActive.value = false;
      });
    };
    // 등록 완료
    const onAddDone = () => {
      getDataFromApi2(1);
    };
    // 등록 완료
    const onAddDone2 = () => {
      getDataFromApi(1);
    };
    const closedModal = () => {
      modalActive.value = false;
    };
    // 수정 완료
    const onEditDone = (row, data) => {
      row.local = data.local;
      tableData.value = tableData.value.slice();
      getDataFromApi2(1);
    };
    // 수정 완료
    const onEditDone2 = () => {
      this.tableData = this.tableData.slice();
      this.getDataFromApi(1);
    };
    const router = useRouter();
    const goList = () => {
      router.push({
        name: "Proposal",
      });
    };
    // 대댓글 삭제하기
    const handleDelete2 = (row, index) => {
      if (confirm("삭제하시겠습니까?")) {
        const data = {};
        row.doubleComment.splice(index, 1);
        data.doubleComment = row.doubleComment;
        data.type = "doubleComment";
        api.put(`manage/class2/comment/${row.id}`, { data }).then(() => {
          this.getDataFromApi(1);
        });
      }
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (row.doubleComment.length > 0) {
        alert("대댓글이 있는 글은 삭제할 수 없습니다.");
        return;
      }
      if (confirm("삭제하시겠습니까?")) {
        const data = {};
        data.state = 0;
        api.put(`manage/class2/comment/${row.id}`, { data }).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);

          const data = {};
          data.commentHit = commentHit.value - 1;
          api
            .put(`manage/class2/proposal/${id.value}`, { data })
            .then(() => {});
        });
      }
    };

    return {
      tableData, // 레코드셋
      tableData2, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      start1,
      start2,
      end1,
      end2,
      content,
      modalActive,
      closedModal,
      comment,
      htmlEditorKey,
      modalModeText,
      id,
      no,
      title,
      verboseSearchOptionManager,
      inputPossibleBranch,
      inputPossibleClassLeader,
      noticeId,
      isComment,
      query,
      reset,
      getDataFromApi,
      getContent,
      doubleCommentAdd,
      doubleCommentEdit,
      onAdd,
      onEdit,
      onAddDone,
      onAddDone2,
      onEditDone,
      onEditDone2,
      goList,
      handleDelete,
      handleDelete2,
      modules,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
#baseinput {
  margin-left: 10%;
  margin-right: 10%;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  background-color: rgba(151, 151, 157, 0.1);
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
p {
  word-break: break-all;
}
.base-button {
  margin: 0px;
  padding: 10px;
}
</style>
