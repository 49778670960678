<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-4">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h3 text-white d-inline-block mb-0">법문주소</h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <div class="row align-items-center">
                <!-- <div class="col-12">
                   <form class="form-inline mb-2"> -->
                <div v-if="verboseSearchOptionSemester" class="col-sm-2">
                  <preset-select
                    v-model="query.semester"
                    placeholder="학기"
                    size="large"
                    :choices="SEMESTER.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div v-if="bsection" class="col-sm-3">
                  <preset-select
                    v-model="query.section"
                    placeholder="과목"
                    size="large"
                    :choices="BSECTION.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>
                <div v-if="ksection" class="col-sm-3">
                  <preset-select
                    v-model="query.section"
                    placeholder="과목"
                    size="large"
                    :choices="KSECTION.map((x) => [x, `${x}`])"
                    @change="getDataFromApi()"
                  />
                </div>

                <!-- </form>
                </div> -->
              </div>
            </div>
            <!-- t: top, b: bottom, s: left, e: right x: left & rigith y: top & bottom -->
            <div class="row" style="position: relative; height: ">
              <div class="col-sm-1">
                <preset-select
                  v-model="query.bound"
                  size="sm"
                  :choices="[
                    ['5', '5'],
                    ['10', '10'],
                    ['15', '15'],
                    ['20', '20'],
                  ]"
                  @change="getDataFromApi(1)"
                />
              </div>
              <div
                class="col"
                style="position: absolute; bottom: 0px; left: 140px; width:auto;"
              >
                total: {{ total }}
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-flush">
                <thead>
                  <th v-for="item in header" :key="item.index">{{ item }}</th>
                </thead>
                <tbody>
                  <tr v-for="row in tableData" :key="row.index">
                    <td>{{ row.no }}</td>
                    <td>{{ row.grade }}</td>
                    <td>{{ row.section }}</td>
                    <td>{{ row.lessonName }}</td>
                    <td>
                      <div
                        class="font-weight-600"
                        style="color: cornflowerblue"
                      >
                        <a @click="goMeet(row.url)">{{ row.url }}</a>
                      </div>
                    </td>
                    <td>{{ row.start }}</td>
                    <td>{{ row.end }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- End List -->
            <!-- Pagination -->
            <div class="card-footer py-4 d-flex justify-content-center">
              <common-pagination
                :rows-per-page="perPage"
                :total="total"
                :model-value="page2"
                @page-change="getDataFromApi"
              />
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import * as api from "@/api";
import { ref } from "vue";
import PresetSelect from "@/components/Inputs/PresetSelect";
import moment from "moment";
import { SEMESTER, BSECTION, KSECTION } from "@/consts/class";

export default {
  // 컴포넌트 초기화
  components: {
    PresetSelect,
  },
  setup() {
    const tableData = ref([]);
    const perPage = ref(20); // 페이지당 레코드 수
    let page2 = ref(1);
    const total = ref(0); // 총 레코드 수
    const header = ref([
      "No",
      "학 년",
      "과목명",
      "법문명",
      "법문주소줄",
      "시작일",
      "종료일",
    ]);
    const query = ref({
      semester: "",
      section: "",
      bound: 20,
    });
    let inputPossible = ref(false);
    let inputPossibleMaster = ref(false);
    let verboseSearchOptionSemester = ref(false);
    const newData = ref({
      lecture: [
        { name: "", url: "" },
        { name: "", url: "" },
        { name: "", url: "" },
      ],
    });
    let modalActive = ref(false);
    const add = ref(false);
    const semester = ref("");
    const year = ref(0);
    const grade = ref("");
    modalActive.value = false;
    let bsection = ref(false);
    let ksection = ref(false);

    const token = api.Auth.tokenContent;
    // grade.value = token.grade;
    // year.value = 2022;
    // semester.value = "9월";
    query.value.bound = token.listType;
    perPage.value = query.value.bound;
    const getDataFromApi = (page = 1) => {
      page2.value = page;
      perPage.value = query.value.bound;
      // const token = api.Auth.tokenContent;
      // query.value.bound = token.listType;
      if (query.value.semester) {
        const temp = query.value.semester.split(" ");
        grade.value = temp[0];
        year.value = temp[1];
        semester.value = temp[2];
      } else {
        grade.value = token.grade;
        year.value = token.year;
        semester.value = token.semester;
      }

      if (query.value.section === "전체") {
        query.value.section = "";
      }
      // if (
      //   query.value.semester &&
      //   (token.role === "manager" || token.role === "master")
      // ) {
      //   const temp = query.value.semester.split(" ");
      //   grade.value = temp[0];
      // }
      if (grade.value === "불교대학") {
        bsection.value = true;
        ksection.value = false;
      } else if (grade.value === "경전대학") {
        bsection.value = false;
        ksection.value = true;
      }

      const url = `manage/class2/lecture2?bound=${query.value.bound}
   &page=${page}&grade=${grade.value}&year=${year.value}&semester=${semester.value}&section=${query.value.section}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;

        if (data.rows > 0) {
          for (const row of d) {
            row.start = moment(row.start).format("YYYY-MM-DD");
            row.end = moment(row.end).format("YYYY-MM-DD");
            if (row.lessonName.length > 25) {
              row.lessonName = row.lessonName.substr(0, 25) + "...";
            }
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    const created = () => {
      // 페이지 생성 시 호출
      const token = api.Auth.tokenContent;
      inputPossible.value =
        token.role.includes("manager") || token.role.includes("master");
      inputPossibleMaster.value = token.role.includes("master");
      verboseSearchOptionSemester.value =
        token.role.includes("master") ||
        token.role.includes("observer") ||
        token.role.includes("manager") ||
        token.role.includes("mentor");
    };
    created();
    getDataFromApi(1);
    const goMeet = (url) => {
      window.open(url);
    };

    const closedModal = () => {
      modalActive.value = false;
    };
    // 등록 모달
    const handleAdd = () => {
      modalActive.value = true;
      newData.value.grade = "";
      add.value = true;
      newData.value.lessonDate = "";
      newData.value.lessonDate2 = "";
      newData.value.start2 = "";
      newData.value.end2 = "";
      newData.value.lessonName = "";
      newData.value.lessonNo = "";
      newData.value.section = "";
      newData.value.grade = "불교대학";
      newData.value.name = "";
      newData.value.lecture = [
        { name: "", url: "" },
        { name: "", url: "" },
        { name: "", url: "" },
      ];
    };
    // 수정 모달
    const handleEdit = (row) => {
      api.get(`manage/class2/lesson/${row.id}`).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        modalActive.value = !modalActive.value;
        const data = response.data.data;
        for (let i = 0; i < 3; i++) {
          if (!data.lecture[i]) {
            data.lecture.push({ name: "", url: "" });
          }
        }
        newData.value = data;
        add.value = false;
      });
    };
    // 등록
    const onAdd = () => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.post("manage/class2/lesson", { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 수정
    const onEdit = (row) => {
      const data = JSON.parse(JSON.stringify(newData.value));
      api.put(`manage/class2/lesson/${row.id}`, { data }).then(() => {
        getDataFromApi(1);
        modalActive.value = false;
      });
    };
    // 삭제하기
    const handleDelete = (row) => {
      if (confirm("삭제하시겠습니까?")) {
        api.delete_(`manage/class2/lesson/${row.id}`).then(() => {
          tableData.value = tableData.value.filter((r) => r.id !== row.id);
        });
      }
    };
    return {
      header,
      page2,
      SEMESTER,
      BSECTION,
      KSECTION,
      bsection,
      ksection,
      semester,
      newData,
      year,
      query,
      getDataFromApi,
      goMeet,
      tableData,
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
      inputPossible,
      inputPossibleMaster,
      verboseSearchOptionSemester,
      modalActive,
      closedModal,
      handleEdit,
      onAdd,
      add,
      onEdit,
      handleAdd,
      handleDelete,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.modal-content {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  text-align: left;
}
table th {
  padding: 12px;
  border-bottom: 2px solid darkgray;
  text-align: center;
  background-color: rgba(184, 219, 196, 0.1);
}
table td {
  padding: 12px;
  text-align: center;
}
table tr:nth-of-type(even) {
  background-color: rgba(226, 226, 245, 0.1);
}
</style>
